import styled from "styled-components";

export const AddNewConceptWrapper = styled.div`
.addConceptInp{
    width:100%;
    margin-top:5%;
    display:block;
    margin-left:auto;
    margin-right:auto;
}
.btnParent{
    margin-top:4%;
    width:100%;
    display:flex;
    justify-content:end;
}
.addConceptBtn{
  
   
}
.addNewConceptParent{
    display: flex;
    gap: 5px;
    justify-content: end;
    font-size: 12px;
}
.addNewButton{
    color: #3f3fc8;
    text-decoration: underline;
    font-weight: bold;
}
`;