import React, { useEffect } from 'react';
import { ShowImagesWrapper } from './ShowImages.style';


export default function ShowImages({attachments}) {
    useEffect(()=>{
        console.log(attachments);
    },[attachments])
  return (
   <ShowImagesWrapper>
     <div style={{ textAlign: "center" }} className='imageSection'>
        {
          attachments?.map((att,index)=>{
            return <img key={index} src={att.attachment_link}
            width={"100%"} style={{display:"block", maxHeight: "600px", maxWidth: "500px", objectFit: "contain", textAlign: "center" }} alt="" />
          })
        }
      </div>

   </ShowImagesWrapper>
  )
}
