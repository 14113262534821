import React from 'react'
import { ShowTextWrapper } from './ShowText.style'
import { Input } from 'antd'

export default function ShowText({data}) {
  return (
   <ShowTextWrapper>
     <label className='title' htmlFor="">{data.title}</label>
     <Input.TextArea  value={data.text} ></Input.TextArea>
   </ShowTextWrapper>
  )
}
