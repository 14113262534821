import axios from "axios";
import { Url } from "../helper/constants";
import Header from "../api/headers";
export default class AuthServices {
    static POSTLogin(data){
        // Header.setHeaders();
        return axios.post(`${Url}/auth/signin`,data);
      }


      static getUserRoles(){
          Header.setHeaders();
        return axios.get(`${Url}/auth/getUserRoles`);
      }
    
      static getAllRoles(){
        Header.setHeaders();
        return axios.get(`${Url}/roles/`);
      }
}