import { device } from "helper/constants";
import  Styled  from "styled-components";



export const CreateContentWrapper = Styled.div`
.createContentListParent{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // height: 55vh;
}
.createContentList{
    padding: 2% 3%;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}


@media ${device.tabletL}{
    .createContentList{
        
        display:grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
}

`;