import styled from "styled-components";


export const ShowQuestionDetailsWrapper = styled.div`

.questionDetails{
     margin-top:5%;
}
.row{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin: 0% 0% 1%;
}

.uploadsSection{
    padding: 0% 2% 2%;
    background: #eefafe;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    margin-top:3%;
}

.uploads{
    font-size: 16px;
    font-weight: bold;
}

.programmerSection{
    padding: 0% 2% 2%;
    background: #eefafe;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    margin-top:3%;
}
.programs{
    font-size: 16px;
    font-weight: bold;
}

.images{
    width:100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 3%;
}
.images img{
    width: 100%;
    height: 100%;
    object-fit: contain;
} 

.resources{    
    width: 100%;
    margin: 0 auto;
    margin-top: 5%;
    text-align: center;
}


.verifierSection{
    padding: 0% 2% 2%;
    background: #eefafe;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    margin-top:3%;
}

.verifiers{
    font-size: 16px;
    font-weight: bold;
}

.commentP{
    margin-top: 4%;
    font-size: 14px;
    font-weight: bold;
}

.commentSection{
    min-height: 80px;
    background: white;
    padding: 2%;
    border-radius: 5px;

}
.addNewConcept{
    width:100%;
    margin-top:2%;
    display:flex;
    justify-content:end;
}
.addNewConcept button{
    font-size: 11px;
    width: 90px;
    height: 30px;
}

.uploader-comment{
  
    min-height: 30px;
    border: 1px solid #e2a6a6;
    border-radius: 5px;
    margin: 2% 0%;
    padding: 2%;
}

.buttonsSections{
    margin-bottom: 3%;
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 5%;
}
.buttonClass{
    border: none;
    /* background: #0061ff; */
    color: white;
    padding: 1% 3%;
    border-radius: 5px;
    cursor: pointer;
}

.link{
    margin-top: 4%;
    text-align: center;
}

.practiceLink{
    border: none;
    background: #395c83;
    /* padding: 2%; */
    width: 150px;
    height: 30px;
    border-radius: 5px;
    color: white;
    cursor: pointer;

}

.commit{
    background: #0061ff;
}
.rtp{
    background:#ffa800;
}   
.rtv{
    background:#ffce00;
}
.discard{
    background: red;
}


.downloadResources{
    width: 168px;
    border: none;
    background: #29bc29;
    color: white;
    border-radius: 5px;
    height: 30px;
    cursor: pointer;
}
`;