import React,{useState} from 'react';
import { ChangePasswordWrapper } from './ChangePassword.style'
import Input from 'antd/es/input/Input'
import SecuredServices from 'api/securedServices';
import { message } from 'antd';

export default function ChangePassword({data,toggleModal}) {

    const [userInput,setUserInput] = useState({
        password:"",
        confirmPassword:""
    });
    const [passwordsMatch,setPasswordsMatch] = useState(false);
        const handleSubmit = ()=>{
        console.log("submit");

        if(userInput.password.length===0 || !userInput.confirmPassword.length===0){
            message.error("Please enter password and confirm password");
            return;
        }
        if(userInput.password!==userInput.confirmPassword){
            message.error("Password and confirm password should match");
            return;
        }
        if(userInput.password.length<4){
            message.error("Password length should be greater or equal to 4");
        }
        let Apidata = {
            userid:data.userid,
            password:userInput.password
        }
        SecuredServices.changeUserPassword(Apidata).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                message.success(res.data.message);
                toggleModal();
            }
            else{
                message.error(res.data.message);
            }
        })
    }
  return (
    <ChangePasswordWrapper>
        <div className='changePasswordForm'>

            <div className='formElement'>
                <label htmlFor="" className='label'>*New Password</label>
                <Input.Password type="password" 
                placeholder="Enter password"
                onChange={(e)=>{

                    setUserInput({...userInput,password:e.target.value})
                    console.log(e.target.value,userInput.confirmPassword);
                    if(e.target.value===userInput.confirmPassword){
                        setPasswordsMatch(true);
                    }
                    else{
                        setPasswordsMatch(false);
                    }
                }
                }
                />
            </div>

            <div className='formElement'>
                <label htmlFor="" className='label'>*Confirm Password</label>
                
                <Input.Password 
                placeholder="Enter confirm password"
                type="password" onChange={(e)=>
                {
                    setUserInput({...userInput,confirmPassword:e.target.value})
                    if(userInput.password===e.target.value){
                        setPasswordsMatch(true);
                    }
                    else{
                        setPasswordsMatch(false);
                    }
                }
                    } />
            </div>
            <div>
                <p style={{color:"red",fontSize:"10px"}}>{passwordsMatch===false && "Passwords doesn't match"}</p>
            </div>
        </div>

        <div className='button'>
                <button className='submitButton' onClick={handleSubmit}>Submit</button>
            </div>
    </ChangePasswordWrapper>
  )
}
