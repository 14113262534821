import styled from "styled-components";

export const SearchBarContainer = styled.div`

.searchBarContainer{
    text-align:right;
    display: flex;
    justify-content: end;
}
.searchBar{
    height: 36px;
    display:flex;
    gap:5px;
    padding: 0 2%;
    width: 30%;
    border: 1px solid #c2c0c0;
    border-radius: 5px;
    margin: 0% 4% 1%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.searchInput{
    border: none;
    background: none;
}
.searchInput:focus{
    outline: none;
    border: none;
}
`;
