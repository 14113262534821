import styled from "styled-components";


export const QuestionDetailsWrapper = styled.div`

.buttonsSection{
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 5rem;
}
.uploaderComment{
    border: 1px solid #cbc5c5;
    border-radius: 5px;
    padding: 0% 2%;
    font-size: 14px;
    font-weight: 500;
    width: 80%;
    margin: 3% auto;
}

.uploaderComment p{
    font-size: 14px;
    color:red;
}

.bg-green{
    background: green;
}
.bg-blue{
    background: #213861;
}
.downloadButton,.SubmitFiles{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    min-width: 120px;
    font-size: 12px;
    height: 30px;
    cursor: pointer;
}
`;
