import React,{useState,useEffect, useRef} from 'react'
import { UploadQuestionWrapper } from './uploadSection.style'
import { Select,Tabs,message,Table } from 'antd'
import { Option } from 'antd/es/mentions'
import { useSelector } from 'react-redux'
import deleteIcon from "assets/images/deleteIcon.svg";
// import axios from 'axios'
import SecuredServices from 'api/securedServices'
import TabPane from 'antd/es/tabs/TabPane'
import { useMediaQuery } from "react-responsive";
import GenricModal from 'components/comman/modalComponents/modal/modal'
import { getDate } from 'helper/utils'
import { useNavigate } from 'react-router-dom'
import { RouteDefinitions } from 'api/routes/routeDefinitions'
import {EditOutlined} from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'

export default function UploadQuestion() {

    const [userInputData,setUserInputData] = useState({
        type:-1,
        topic_id:-1,
        grade_id:-1,
        subject_id:1,
    })
    const userData = useSelector(state=>state.user);
    const navigate = useNavigate();
    const [file,setFile] = useState(null);
    const [activeKey,setActiveKey] = useState("1");
    const [selectedRow,setSelectedRow] = useState({});
    const [showModal,setShowModal] = useState(false);
    const [previews, setPreviews] = useState([]);
    const [selectedPreviewIndex,setSelectedPreviewIndex] = useState(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [topics,setTopics] = useState([]);
    let [dataSource,setDataSource] = useState([]);
    const cameraInputRef = useRef();
    const galleryInputRef = useRef();
    const [cropImageModal,setCropImageModal] = useState(false);
    const Tablet = useMediaQuery({ query: "(max-width: 1024px)" });
    const handleSubmit = ()=>{
        setDisableSubmit(true);
        console.log(disableSubmit);
        const q_name = `${userData.username}_${userInputData.topic_id}_${Date.now()}`;
        let data = new FormData();

        Object.entries(userInputData).forEach(([key,value])=>{
            data.append(key,value);
        })

        data.append("q_name",q_name);
        data.append("topic_name",topics.find(topic=>topic.topic_id===userInputData.topic_id)?.name);
       
        previews.map((preview)=>{
            data.append("files",preview.file);
        })
        // data.append('files', file);
        console.log(data.getAll("q_name"));
        console.log(data.getAll("files"));
        console.log(data.getAll("subject_id"));
        console.log(data.getAll("topic_id"));
        console.log(data.getAll("grade_id"));
        console.log(data.getAll("type"));
        console.log(data.getAll("comment"));

        let submitflag =1;
        if( data.get("q_name").length==0){
            submitflag = 0;
            message.error("Question name cannot be empty");
        }
        if(data.get("subject_id")==-1){
            submitflag = 0;
            message.error("Please select a subject");
        }

       
      
        if(  data.get("grade_id")==-1){
            submitflag = 0;
            message.error("Please select a grade");
        }
        if( data.get("type")==-1){
            submitflag = 0;
            message.error("Please select a question type");
        }
        if( data.get("topic_id")==-1){
            submitflag = 0;
            message.error("Please select a topic");
        }

       

        if(submitflag==1 && previews.length==0 ){
            submitflag = 0;
            message.error("Please upload a file");
            
        }
        if(submitflag==1 && previews.length>5){
            submitflag = 0;
            message.error("Cannot upload more than 5 files");
        }
        if(submitflag===1){
            SecuredServices.uploadQuestion(data).then((res)=>{
                setDisableSubmit(false);
                console.log(res);
                if(res.data.status==1){
                    setPreviews([]);
                    setFile(null);

                    setUserInputData(
                        {
                            type:-1,
                            topic_id:-1,
                            grade_id:-1,
                            subject_id:1,
                        }
                    );
                    message.success(res.data.message);
                }
                else{
                    message.error(res.data.message);
                }
            }).catch((err)=>{
                console.log(err);
                message.error(err.message);
                setDisableSubmit(false);
            })

        }
        else{
            setDisableSubmit(false);
        }
      

        
    }

        useEffect(()=>{
            if(activeKey=="2"){
                SecuredServices.getUploadedQuestions().then((res)=>{
                    console.log(res.data.data);
                    setDataSource(res.data.data);
                })

            }
        },[activeKey])
    useEffect(()=>{
        console.log(disableSubmit);
    },[disableSubmit])
    const removeImage= (index)=>{
        let newPreviews = [...previews];

        newPreviews.splice(index,1);
        setPreviews(newPreviews);
    }
    const handleImage = (e)=>{
        const files = Array.from(event.target.files);
        console.log(e.target.files);
        setFile(files);

        const newPreviews = files.map(file => {
            return {
                file: file,
                preview:URL.createObjectURL(file)}
          });
          let newPreviews2 = [...previews,...newPreviews];
          setPreviews(newPreviews2);
    }
    const handleGradeChange = (value)=>{

        setUserInputData({...userInputData,grade_id:value});
        if(value==-1){
            setTopics([]);
            return;
        }
        SecuredServices.getTopics(value).then((res)=>{
            console.log(res);
            setTopics(res.data.data.data);
        })
    }
    

    useEffect(()=>{
        console.log(userData);
    },[userData])

    const columns = [
        {
            title:"qName",
            dataIndex:"q_name",
            sorter: (a, b) => a.q_name - b.q_name,

        },
        {
            title:"Grade",
            dataIndex:"grade_id",
            sorter: (a, b) => a.grade_id - b.grade_id,
        },
        {
            title:"Topic",
            dataIndex:"topic_name",
            sorter: (a, b) => a.topic_name - b.topic_name,
        },
        {
            title:"Q Type",
            dataIndex:"type"

        },
        {
            title:"Status",
            dataIndex:"status",
            sorter: (a, b) => a.status - b.status,
        },
        {
            title:"date",
            render:(text,record,index)=>{
                return <p>{getDate(record.createdAt)}</p>
            },
            sorter: (a, b) => a.createdAt - b.createdAt,
        },
        {
            title:"Images",
            key:"submitted_files",
            render: (text, record, index) => (
                <a  onClick={()=>{
                    console.log(record);
                    setSelectedRow(record);
                    setShowModal(true);
                }}>View</a>
            )
        },
        {
            title:"Action",
            key:"action",
            render: (text, record, index) => (
                <a href="" style={{display:record.status=="resubmit"?"block":"none"}}>re-submit</a>
            ),  
        }
       
    ]


    const handlePreviewImageClick = (imageObj,index)=>{
        let url =  imageObj.preview;
        console.log(url);
        setSelectedPreviewIndex(index);
        setCropImageModal(true);
    }
    const handleCropImage = (cropImage)=>{

        let newPreviews = [...previews];
        newPreviews[selectedPreviewIndex]= cropImage;
        setPreviews(newPreviews);
        setCropImageModal(false);
    }
 
      return (
    <UploadQuestionWrapper>
         <h3>Upload Section</h3>

        <Tabs defaultActiveKey='1'
         onChange={(key)=>setActiveKey(key)}
        >
            <TabPane tab="Upload" key="1">
            <div>
            <div className='uploadFormParent'>
            <div className='uploadFormElement'>
                    <label htmlFor=""  className='label'>*Subject:</label>
                    <Select defaultValue={1} style={{width:"100%"}} onChange={(value)=>setUserInputData({...userInputData,subject_id:value})} >
                        <Option value={1}>Maths</Option>
                        {/* <Option value={1}>MCQ</Option> */}
                    </Select>
                </div>

                <div className='uploadFormElement'>
                    <label htmlFor=""  className='label'>*Grade:</label>
                    <Select defaultValue={-1} style={{width:"100%"}} value={userInputData.grade_id} onChange={handleGradeChange} >
                        <Option value={-1}>Select Grade</Option>
                        <Option value={6}>6</Option>
                        <Option value={7}>7</Option>
                        <Option value={8}>8</Option>
                        <Option value={9}>9</Option>
                        <Option value={10}>10</Option>
                        {/* <Option value={1}>MCQ</Option> */}
                    </Select>
                </div>
                <div className='uploadFormElement'>
                    <label htmlFor=""  className='label'>*Question Type:</label>
                    <Select defaultValue={-1} style={{width:"100%"}} value={userInputData.type} onChange={(value)=>setUserInputData({...userInputData,type:value})} >
                        <Option value={-1}>Select Question Type</Option>
                        <Option value={1}>Descriptive Question</Option>
                        <Option value={2}>MCQ</Option>
                    </Select>
                </div>
                <div className='uploadFormElement'>
                    <label htmlFor=""  className='label'>*Topic Name:</label>
                    <Select defaultValue={-1} style={{width:"100%"}} value={userInputData.topic_id} disabled={topics.length===0} onChange={(value)=>setUserInputData({...userInputData,topic_id:value})} >
                        <Option value={-1}>Select Topic</Option>
                        {
                            topics.map((topic,index)=>{
                                return <Option key={index} value={topic.topic_id}>{topic.name}</Option>
                            })
                        }
                    </Select>
                </div>
                <div className='uploadFormElement'>
                    <label htmlFor=""  className='label'>Comment:</label>
                    <TextArea rows={4} value={userInputData.comment} onChange={(e)=>setUserInputData({...userInputData,comment:e.target.value})} />
                </div>
                {/* <div className='uploadFormElement'>
                    
                    <label htmlFor=""  className='label'>*Question Image(s):</label>
                   
                </div> */}
                <div className='uploadFormElement'>

                    <label htmlFor=""  className='label'>*Question Image(s):</label>

                    <input type='file'
                     maxLength={5}
                     accept="image/png ,image/jpeg ,image/jpg"
                     multiple="multiple"
                     style={{display:"none"}}
                     capture="user"
                     ref={cameraInputRef}
                      onChange={handleImage} />

                    <input type='file'
                     maxLength={5}
                     accept="image/png ,image/jpeg ,image/jpg"
                     style={{display:"none"}}
                     ref={galleryInputRef}
                     multiple="multiple" onChange={handleImage} />
                     <div className='buttonsSections'>
                        {Tablet && <button  className='cameraButton'  onClick={()=>cameraInputRef.current.click()} >Camera</button>}
                        <button className='gallaryButton'  onClick={()=>galleryInputRef.current.click()}>Gallary</button>
                     </div>
                </div>

                <div className='preview-images'>
                    {
                        previews.map((imgObj,index)=>{
                            return <div className='preview'>
                                    <img className='deleteIcon' src={deleteIcon} width={20}  onClick={()=>removeImage(index)} alt="" />
                                    <EditOutlined className='editIcon' />
                                  <img src={imgObj.preview} className='preview-img'  key={index} onClick={()=>handlePreviewImageClick(imgObj,index)} />
                                </div>
                          
                        })
                    }
                </div>

                <div className='submitButton' onClick={handleSubmit} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                    <p>Submit</p>
                </div>

            </div>

           
        </div>
            </TabPane>

            <TabPane tab="Submitted" key="2">
                <div>
                <Table dataSource={dataSource} columns={columns} />
                </div>
            </TabPane>
        </Tabs>
     <GenricModal 
     title={"View Images"}
     data={selectedRow.attachments}
     isOpen={showModal}
     onClose={()=>setShowModal(false)}
     closable={true}
     width={700}
     toggle={()=>setShowModal(!showModal)}
     />

<GenricModal 
     title={"Crop Image"}
     data={previews[selectedPreviewIndex]?.preview}
     isOpen={cropImageModal}
     onSubmitClick={(crop)=>handleCropImage(crop)}
     onClose={()=>setCropImageModal(false)}
     closable={true}
     width={700}
     toggle={()=>setCropImageModal(!cropImageModal)}
     />
    </UploadQuestionWrapper>
  )
}
