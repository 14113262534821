import styled from "styled-components";


export const TextInputModalWrapper = styled.div`
.buttonsSections{
    display: flex;
    justify-content: end;
    gap: 10px;
    margin-top: 20px;
    margin-right: 20px;
}
.sliderRange{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    margin-top: -3%;
}
`