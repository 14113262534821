import React,{useEffect, useState} from 'react'
import { EditUserWrapper } from './EditUser.style'
import Input from 'antd/es/input/Input'
import { Select, message } from 'antd'
import SecuredServices from 'api/securedServices'
import AuthServices from 'api/authServices'

export default function EditUser({data}) {

    const [userInputData,setUserInputData] = useState({
        username:data.username,
        first_name:data.first_name,
        last_name:data.last_name,
        email:data.email,
    })
    const [roles,setRoles] = useState([]);
    const[rolesData,setRolesData] = useState([]);

    useEffect(()=>{

        let rolesArr = [];
        data.roles.forEach(role => {
            if(role.roleid==null){
                return;
            }
            rolesArr.push(role.roleid) 
        });
        setRoles(rolesArr);
    },[data])

    useEffect(()=>{
        AuthServices.getAllRoles().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                setRolesData(res.data.data);
            }
        })
    },[])

    const handleSubmit = ()=>{
        let userdata = {
            userid :data.userid,
            data:userInputData,
            roles:roles
        }

        console.log(userdata);
        SecuredServices.updateUser(userdata).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                message.success(res.data.message);
                window.location.reload();
            }
            else{
                message.error(res.data.message);
            }
        })
    }

  return (
    <EditUserWrapper>
        <h3>User Details</h3>
        <div className='form'>
            <div className='formElement'>
                <label className='label'  >Username:</label>
                <Input 

                className='input'
                placeholder='Enter Username'
                value={userInputData.username}
                onChange={(e)=>setUserInputData({...userInputData,username:e.target.value})}
                 />
            </div>
            <div className="formElement">
                <label className='label'>First Name:</label>
                <Input 
                className='input'
                placeholder='Enter First Name'
                value={userInputData.first_name}
                onChange={(e)=>setUserInputData({...userInputData,first_name:e.target.value})}
                 />
            </div>
            <div className="formElement">
                <label className='label'>Last Name:</label>
                <Input className='input'
                placeholder='Enter Last Name'
                value={userInputData.last_name}
                onChange={(e)=>setUserInputData({...userInputData,last_name:e.target.value})}
                 />
            </div>
            <div className="formElement">
                <label className='label'>Email:</label>
                <Input className='input'
                placeholder='Enter Email'
                value={userInputData.email}
                onChange={(e)=>setUserInputData({...userInputData,email:e.target.value})}
                 />
            </div>
            <div className="formElement">
                <label className='label'>Roles:</label>
                <Select 

                mode='multiple'
                value={roles}
                style={{width:"100%"}}
                onChange={(value)=>setRoles(value)}
                >
                   {
                       rolesData.map((role)=>{
                           return <Select.Option key={role.roleid} value={role.roleid}>{role.name}</Select.Option>
                       })
                   }
                </Select>
            </div>
            <div className='button'>
                <button className='submitButton' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    </EditUserWrapper>
  )
}
