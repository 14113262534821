import styled from 'styled-components';

export const DashboardScreenWrapper = styled.div`
display: flex;


.left{
    background: white;
    border-right: 2px solid black;
    // width: 20%;
}
.right{
    // width: 80%;
    height: 100vh;
    background: white;
}
.ant-layout-sider{
    background: white !important;
    // border-right: 2px solid black;
}
.label{
    display: block;
    margin-bottom: 5px;
}
p{
    margin: 0 !important;
}


`;