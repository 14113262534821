import React,{ useState ,useEffect} from 'react'
import { ManageUsersWrapper } from './ManageUsers.style';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal, Space, Table, message } from 'antd';
import SecuredServices from 'api/securedServices';
import GenricModal from 'components/comman/modalComponents/modal/modal';

export default function ManageUser() {



    const [usersData,setUsersData] = useState([]);
    const [editUserModal,setEditUserModal] = useState(false);
    const [editUserData,setEditUserData] = useState({});
    const [changePassModal,setChangePassModal] = useState(false);
    const [createNewUserModal,setCreateNewUserModal] = useState(false);
     



    const columns = [
        {
            title:'userid',
            dataIndex:'userid',
            key:'userid'
        },
        {
            title:"username",
            dataIndex:"username",
            key:"username"
        },
        {
          title: 'Name',
          dataIndex: 'first_name',
          key: 'name',
          render: (text,record) => {
            return <p>{record.first_name} {record.last_name}</p>
          },
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Roles',
          key: 'role',
          render: (_, record) => {
              return <div> 
                {
                    record.roles.map((role,index)=>{
                        return <p key={index} >{role.name}</p>
                    })
                }
              </div>
          }
        },
        {
          title: 'Action',
          key: 'action',
          render:(text,record)=>{
            return <div className='buttonsDiv'>
                {/* <button className='deleteUser' onClick={()=>handleClickOnRemoveUser(record.userid)}>Delete</button> */}
                <button className='editButton' onClick={()=>{
                    setEditUserModal(true);
                    setEditUserData(record);
                }}>Edit</button>
                <button className='viewButton' onClick={
                    ()=>{

                        setChangePassModal(true)
                        setEditUserData(record);
                        }}>Change Password</button>

            </div>
          }
        }
    ]

    useEffect(()=>{
        getUsers();
    },[])

    const getUsers = ()=>{
        SecuredServices.getAllUsers().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                setUsersData(res.data.data);
            }
            else{
                message.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
            message.error(err.message);
        })
    }


    const handleClickOnEditUser = (record)=>{
        
    }

  return (
    <ManageUsersWrapper>
        <div className='flex space-between align-center'>
            <h4 className=''>Manage Users</h4>
            <div className='createNewUser'>
                <PlusCircleOutlined /> 
               <p className='createNewUser' onClick={()=>setCreateNewUserModal(true)}>New User</p>
            </div>
        </div>
        <div>
            <Table dataSource={usersData} columns={columns} />
        </div>

        <GenricModal 
            closable={true}
            width={700}
            title={"Edit User Details"}
            isOpen={editUserModal}
            toggle={()=>setEditUserModal(false) }
            data={editUserData}
        />

        <GenricModal 
            closable={true}
            width={500}
            title={"Change Password"}
            isOpen={changePassModal}
            toggle={()=>setChangePassModal(false) }
            data={editUserData}
        />


        <GenricModal 
            closable={true}
            width={700}
            title={"Create New User"}
            isOpen={createNewUserModal}
            toggle={()=>setCreateNewUserModal(false) }
            data={editUserData}
        />

    </ManageUsersWrapper>
  )
}



    //// const handleClickOnRemoveUser = (userid)=>{
    ////     Modal.confirm({
    ////         title: 'Are you sure you want to delete this user?',
    ////         okText: 'Delete',
    ////         cancelText: 'Cancel',
    ////         okType: 'danger',
    ////         onCancel() {},
    ////       });
    //// }   
    //// const removeUser = (userid)=>{
    ////     SecuredServices.removeUser(userid).then((res)=>{
    ////         console.log(res.data);
    ////         if(res.data.status==1){
    ////             message.success(res.data.message);
    ////             getUsers();
    ////         }
    ////         else{
    ////             message.error(res.data.message);
    ////         }
    ////     }).catch((err)=>{
    ////         console.log(err);
    ////         message.error(err.message);
    ////     })
    //// }
