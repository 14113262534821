import {
  Pagination,
  message,
  Input
} from "antd";
import useImage from "use-image";
import React, { useState, useEffect, useRef } from "react";
import { Stage, Layer, Image,Text,Rect } from 'react-konva';
import jsPdf from "jspdf";

import { useNavigate } from 'react-router-dom';
import { RouteDefinitions } from 'api/routes/routeDefinitions';



import Yes from "assets/images/yestick2.png";
import { useMediaQuery } from "react-responsive";
import No from "assets/images/no.png";
import Circle from "assets/images/ellipse.png";

import AssigmentDetailsWrapper from "./VerifyMission.style";

import { pdfjs } from "react-pdf";
import { Promise } from "q";

import { convertPdfToImages } from "helper/utils";
import SecuredServices from "api/securedServices";
import GenricModal from "components/comman/modalComponents/modal/modal";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



function VerifyMission() {
  const Navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const concept = {
    concept_id: params.get('concept_id'),
    name: params.get('concept_name'),
  }
  const [pdfurl, setPdfurl] = useState(null);

  const qname = params.get('qname');
  const [pages, setPages] = useState(0);
  const [activePage, setactivePage] = useState(1);
  const [page, setPage] = useState(0);
  const [assignmentDetails, setAssignmentDetails] = useState({});
  const MobileL = useMediaQuery({ query: "(max-width: 500px)" });

  const [image, setImage] = useState(null);
  
  const [comment, setComment] = useState("");
  const [media, setMedia] = useState([]);
  const [data, setData] = useState([]);
  const [jimpEditor, setJimpEditor] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [jimpType, setJimpType] = useState("");
  const [updatedFiles, setUpdatedFiles] = useState([]);


  const [annotations, setAnnotations] = useState([]);
  const [annotationHistory, setAnnotationHistory] = useState([]);
  const [missionVerificationDetails, setMissionVerificationDetails] = useState({});
  const [images, setImages] = useState([]);
  const [yesIcon] = useImage(Yes);
  const [noIcon] = useImage(No);
  const [circleIcon] = useImage(Circle);
  const parentRef = useRef();
  const stageRef = useRef();
  const [showTextModal,setShowTextModal] = useState(false);
  const [recentClickedPosition,setRecentClickedPosition] = useState(null);
  const [editTextId,setEditTextId] = useState(null);
  const [editTextObj,setEditTextObj] = useState(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });


  useEffect(() => {
    SecuredServices.getVerificationDetails(true,qname).then((res) => {
      console.log(res.data);
      if (res.data.status == 1) {
        
        setPdfurl(res.data.data[0].url.split('mission/')[1]);
        setMissionVerificationDetails(res.data.data);


      }
    })
  }, [qname])




  useEffect(() => {
    if (parentRef.current) {
      // setDimensions({
      //   width: parentRef.current.offsetWidth,
      //   height: parentRef.current.offsetHeight
      // });
    }
  }, []);

  useEffect(() => {
    const loadImages = async () => {
      if (!pdfurl) return;

      console.log(pdfurl);

      let obj = {
        file_url: `https://assignment-prod.s3.ap-south-1.amazonaws.com/assignment/mission/${pdfurl}`,
      };
      setAssignmentDetails(obj);
      console.log(obj.file_url);

      try {
        let files = await convertPdfToImages(obj.file_url, true,MobileL); // Assuming this function converts PDF to image URLs
        console.log(files);

        // Temporary array to hold the images
        const loadedImages = [];
        let annotationH = [];

        // Load images sequentially
        for (let file of files) {
          const img = new window.Image();
          img.src = file;
          img.onload = () => {
            // console.log("Image loaded:", img);
           let width= img.naturalWidth;
            let height = img.naturalHeight;
            console.log(width, height);
            console.log(dimensions);
            console.log(img.width);
            console.log(img.height);
            // Calculate the new height based on the aspect ratio
            const aspectRatio = height / width;
            console.log(aspectRatio);
            let newHeight;
            let newWidth;
            if(MobileL){
              height = height*(parentRef?.current?.offsetWidth/width);
              newHeight =parentRef?.current?.offsetHeight;
              newWidth= parentRef?.current?.offsetWidth;
            }
            else{
              newHeight = parentRef?.current?.offsetWidth* aspectRatio;
              newWidth =  parentRef?.current?.offsetWidth;
            }
           
            console.log(newHeight);

              setDimensions({
                width:newWidth,
                height: newHeight,
              })
          };
          annotationH.push([]);
          loadedImages.push(img); // Push each image into the temporary array
        }

        // Update the state once with all the loaded images
        setImages(loadedImages);
        setImage(loadedImages[0]);
        setAnnotationHistory(annotationH);
        let med = [];
        loadedImages.map((img, index) => {
          let arr = [img];
          med.push(arr);
        })
        setMedia(med);
        setactivePage(0);
        // console.log(med[activePage][med[activePage].length-1]); 
        setPages(loadedImages.length);

      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    loadImages(); // Call the async function to load images
  }, [pdfurl, convertPdfToImages]);


  const showKonvaEditor = async (event,data) => {
  
    console.log(event);
    const { x, y } = event.target.getStage().getPointerPosition();
    let icon;


    if(editTextId!=null && jimpType=="text"){

      let ann = [...annotationHistory];
      let annotate = ann[activePage];
      annotate.forEach(ele=>{
        if(ele.id == editTextId){
          ele.text = data.text;
          ele.fontSize = data.fontSize;
        }
      })
      setEditTextId(null);
      setEditTextObj(null);
      return;
    }

    // Based on the selected tool, assign the corresponding icon
    switch (jimpType) {
      case "correct":
        icon = {id: `${Date.now()}_${Math.floor(100 + Math.random() * 899)}`, type: "correct", image: yesIcon, x, y };
        break;
      case "incorrect":
        icon = {id: `${Date.now()}_${Math.floor(100 + Math.random() * 899)}`, type: "incorrect", image: noIcon, x, y };
        break;
      case "circle":
        icon = {id: `${Date.now()}_${Math.floor(100 + Math.random() * 899)}` ,type: "circle", image: circleIcon, x, y };
        break;
      case "text":
        icon = {id: `${Date.now()}_${Math.floor(100 + Math.random() * 899)}`,type:"text",text: data.text,x,y,fontSize:data.fontSize}; 
        break;
      default:
        return;
    }
    console.log(icon);

    // Add the icon to the annotations array
    let ann = [...annotationHistory];
    ann[page] = [...ann[page], icon];
    setAnnotationHistory(ann);
    console.log(ann);
    console.log(x, y);

  }


  const PageHandler = async (page) => {
    console.log(annotations);
    await saveUpdatedImage();
    setactivePage(page);
    setPage(page - 1);
  };

  const saveUpdatedImage = () => {
    //create a promise
    console.log("saveUpdatedImage");

    const promise = new Promise((resolve, reject) => {
      if (stageRef.current) {
        let stage = stageRef.current;
        stage.attrs.width =images[page].naturalWidth;
        stage.attrs.height = images[page].naturalHeight;
        const dataURL = stage.toDataURL({
          mimeType: 'image/png',
          quality: 2.0,
        });
        // console.log(dataURL); // The base64 encoded image string
        let updatedFi = updatedFiles;
        updatedFi[page] = dataURL;
        setUpdatedFiles([...updatedFi]);
        // console.log(file);
        resolve(dataURL);
      }
      else {
        reject();
      }
    });
    return promise;

  };



  const enableJimpEditor = (type) => {
    if (jimpEditor === "jimp" && jimpType === type) {
      // saveJimpImagesData();
      setJimpEditor("");
    }
    else {
      setJimpEditor("jimp");
    }
    setJimpType(type);
  }

  const clearAllEdits = () => {
    let ann = [...annotationHistory];
    ann[page] = [];
    setAnnotationHistory(ann);

  }

  const undoEdit = () => {
    let ann = [...annotationHistory];
    ann[page].pop();
    setAnnotationHistory(ann);
  };


  // const generatePDF = (imageArray) => {
  //   const doc = new jsPdf(); // Create a new jsPDF instance
    
  //   imageArray.forEach((imgData, index) => {
  //     if (index > 0) {
  //       doc.addPage(); // Add a new page for each image after the first one
  //     }
      
  //     // Add the image to the PDF
  //     let width = (dimensions.width/2)>300?300:dimensions.width/2;
       
  //     doc.addImage(imgData, 'PNG', 0, 0, width,0); // Adjust dimensions and positioning as needed
         
  //   });

  //   const pdfBlob  =  doc.output('blob');

  //   // Save the PDF
  //   console.log(doc);
  //   return pdfBlob;
  // };

  const generatePDF = async (imageArray) => {
    const doc = new jsPdf(); // Create a new jsPDF instance
    
    // Get PDF page dimensions (in mm for A4)
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = doc.internal.pageSize.getHeight();
   
    const promises = imageArray.map((imgData, index) => {
      return new Promise((resolve) => {
        const img = new window.Image();
        img.src = imgData;
  
        img.onload = () => {
          img.width = img.width>pdfWidth?img.width:pdfWidth;
          img.height = img.height>pdfHeight?img.height:pdfHeight;
          const imgWidth = img.width;
          const imgHeight = img.height;
        
          // Calculate the scale to fit the image within the PDF dimensions
          const aspectRatio = imgWidth / imgHeight;
          console.log(imgWidth,imgHeight);
          let width, height;
          if (aspectRatio > 1) { // Landscape
            width = pdfWidth*0.8;
            height = (pdfWidth / aspectRatio)*0.8;
          } else { // Portrait
            height = pdfHeight*0.8;
            width = (pdfHeight * aspectRatio)*0.8;
          }
  
          console.log(height,width);
          // Position the image to be centered on the page
          const x = (pdfWidth - width) / 2<0?0:(pdfWidth-width)/2;
          const y = (pdfHeight - height) / 2;
          console.log(x,y);
          if (index > 0) {
            doc.addPage(); // Add a new page for each image after the first one
          }
          
          // Add the image to the PDF
          doc.addImage(imgData, 'PNG', x, y, width, height);
          
          resolve(); // Resolve the promise when the image is added
        };
      });
    });
    console.log('waiting here for completing the above');
    await Promise.all(promises); // Wait for all images to load and be processed
    console.log('waiting here for completing the above');
    console.log(doc);

    const pdfBlob = doc.output('blob');
    console.log(pdfBlob);
   
    return pdfBlob; // Return the PDF Blob
  };

  const handleVerificationSubmit = async (isAccept) => {
    setDisableSubmit(true);
    await saveUpdatedImage();
    let flag = 1;
    annotationHistory.map((ann) => {
      if (ann.length == 0) {
        message.error("You have not checked all the images, please check all the images");
        flag = 0;
        return;
      }
    });
   
    if (flag == 0) {
      setDisableSubmit(false);
      return;
    }
    console.log(updatedFiles);
    let pdfBlob = await generatePDF(updatedFiles);

    // //temporary code for debug
    // const blobURL = URL.createObjectURL(pdfBlob);

    // // 2. Create a temporary <a> element
    // const link = document.createElement('a');

    // // 3. Set the href of the link to the blob URL and the download attribute
    // link.href = blobURL;
    // link.download = 'downloaded-file.pdf'; // The filename to download as

    // // 4. Programmatically click the link to trigger the download
    // link.click();

    // // 5. Clean up the URL object (optional but recommended)
    // URL.revokeObjectURL(blobURL);
    // return;

    // //temporary code ends here

    console.log(missionVerificationDetails);
    let data = {
      isAccept: isAccept,
      concept: concept,
      q_id: missionVerificationDetails[0].q_id,
    }
    const formData = new FormData();
    formData.append('pdf_data', pdfBlob,'verificationPdf.pdf');
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })

    console.log(formData);
    SecuredServices.submitVerificationMission(formData).then((res) => {
      if (res.data.status == 1) {
        message.success("Verification Submitted Successfully");
        Navigate(RouteDefinitions.ROUTE_VERIFY, { replace: true });
      }
    }
    ).catch((err) => {
      console.log(err);
    }).finally(() => {
      setDisableSubmit(false);
    })


  }

  const getUserText = (event)=>{
    console.log(event);
    setRecentClickedPosition(event);
    setShowTextModal(true);
  }

  const onAddTextSubmitClick = (text)=>{
    console.log(text);
    showKonvaEditor(recentClickedPosition,text)
    setShowTextModal(false);

  }

  return (
    <div>
      {
        <AssigmentDetailsWrapper>
          <div className="mt-10 card" style={{ height: "100%", overflow: "auto" }}>
            <div className="grid" >


              <div className="pdfImagesDiv" ref={parentRef} style={{ height: "100%" }}>
                <Stage
                  ref={stageRef}
                  width={MobileL?dimensions.width: dimensions.width>parentRef?.current?.offsetWidth?parentRef?.current?.offsetWidth:dimensions.width}
                  height={dimensions.height}
                  onClick={(e)=>{jimpType=="text"?getUserText(e):showKonvaEditor(e)}}
                  onTouchEnd={(e)=>{jimpType=="text"?getUserText(e):showKonvaEditor(e)}}
                  // style={{padding:"200px"}}
                  pixelRatio={window.devicePixelRatio}
                  style={{display:"flex",justifyContent:"center",marginLeft:MobileL?"0":"10%"}}
                  // draggable = {MobileL}
                  // dragDistance={}

                >
                  <Layer >
                    {/* Render the PDF image */}

                    <Image  className="imageClass"  image={media[page] && media[page].length != 0 ? media[page][media[page].length - 1] : null} x={0} y={0} width={MobileL?dimensions.width: dimensions.width} height={MobileL?dimensions.height:dimensions.height-50}  />
                    {/* Render all the placed annotations (icons) */}
                    {annotationHistory[page]?.map((annotation, i) => {
                      console.log(annotation,i);
                      if(annotation.type == "text"){
                        //this will be used for showing text on the screen

                        return  <Text
                        key={i}
                        id={annotation.id}
                        x={annotation.x}
                        y={annotation.y}
                        text={annotation.text}
                        textDecoration="underline"
                        fontSize={annotation.fontSize?annotation.fontSize:25}
                        fill="red"
                        draggable
                        fontFamily="Caveat"
                        // onDragStart={(e) =>{
                        //   console.log(e,"click")
                        //   setEditTextId(annotation.id)
                        //   setEditTextValue(annotation.text)
                        // }}
                        onClick={(e) =>{
                          console.log(e,"click")
                          setEditTextId(annotation.id)
                          setEditTextObj({text:annotation.text,fontSize:annotation.fontSize})
                        }}
                      />
                      }
                      if (annotation.type == "correct") {
                        return <Image
                          key={i}
                          image={annotation.image}
                          x={annotation.x - 10}
                          y={annotation.y - 20}
                          width={50}
                          height={25}
                          draggable
                        />
                      }
                      else if (annotation.type == "incorrect") {
                        return <Image
                          key={i}
                          image={annotation.image}
                          x={annotation.x}
                          y={annotation.y}
                          width={20}
                          height={20}
                          draggable
                        />
                      }
                      else {
                        return <Image
                          key={i}
                          image={annotation.image}
                          x={annotation.x - 40}
                          y={annotation.y - 40}
                          width={100}
                          height={70}
                          draggable
                       
                        />
                      }
                    }

                    )}
                  </Layer>
                </Stage>

              </div>

            </div>
          </div>

          <div className=" pt-10 paginationAndEditButtons">

            <div style={{ width: "50%", textAlign: "start" }}>
              <Pagination
                defaultCurrent={1}
                total={pages}
                defaultPageSize={1}
                current={activePage}
                onChange={PageHandler}
              /></div>
            <div className="editButtons">
              {/* <div className="rotate hovering" onClick={rotateClick}>
                Rotate <i class="bi bi-arrow-clockwise"></i>
              </div> */}
               {
                jimpEditor !== "marker" ? <div className={(jimpEditor === "jimp" && jimpType === "text") ? "text-rub highlightButton4 hovering" : "text-rub hovering"} onClick={() => enableJimpEditor("text")}>
                 <span>Text</span>
                </div>
                  : ""}
              {
                jimpEditor !== "marker" ? <div className={(jimpEditor === "jimp" && jimpType === "correct") ? "right-rub highlightButton1 hovering" : "right-rub hovering"} onClick={() => enableJimpEditor("correct")}>
                  <span class="bi bi-check" style={{ "fontSize": "20px" }}></span>
                </div>
                  : ""}
              {
                jimpEditor !== "marker" ? <div className={(jimpEditor === "jimp" && jimpType === "incorrect") ? "wrong-rub highlightButton2 hovering" : "wrong-rub hovering"} onClick={() => enableJimpEditor("incorrect")}>
                  <span class="bi bi-x" style={{ "fontSize": "20px" }}></span>
                </div> : ""
              }
              {
                jimpEditor !== "marker" ? <div className={(jimpEditor === "jimp" && jimpType === "circle") ? "circle-rub highlightButton3 hovering" : "circle-rub hovering"} onClick={() => enableJimpEditor("circle")}>
                  <span class="bi bi-circle" style={{ "fontSize": "14px" }}></span>
                </div> : ""
              }
              {
                jimpEditor !== "marker" ? <div className="undo-rub hovering" onClick={undoEdit}>
                  Undo
                </div> : ""
              }
              {
                jimpEditor !== "marker" ? <div className="clearAll-rub hovering" onClick={clearAllEdits}>
                  Clear
                </div> : ""
              }
            </div>
          </div>
          {/* <div className='commentBox'>
            <label htmlFor="" className='label'>*Comments: (write ok if no error found)</label>
            <Input.TextArea
              rows={3}
              value={comment}
              placeholder='Please Enter your comments here. '
              name=""
              onChange={(e) => setComment(e.target.value)}
            />
          </div> */}
          <div className="text-center pt-10" style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "2%" }}>
           
            <button className="rejectVerificationButton" style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}} onClick={() => handleVerificationSubmit(false)}>Reject</button>
            <button className="acceptVerificationButton" style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}} onClick={() => handleVerificationSubmit(true)}>Accept</button>
          </div>
        </AssigmentDetailsWrapper>}

        <GenricModal 
        title="Add Text"
        data={editTextObj}
        isOpen={showTextModal}
        onSubmitClick={onAddTextSubmitClick}
        onCancel={()=>{
          setEditTextId(null);
          setEditTextObj(null);
          setShowTextModal(false)}}
              />
    </div>


  );
}



export default VerifyMission;
