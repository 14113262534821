import styled from "styled-components";


export const ManageConceptsWrapper = styled.div`  
.manageConceptsParent{
    max-width: 100%;
    // border: 1px solid #d5d5d5;
    // border-radius: 10px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    // padding: 2%;
    // height: 60vh;
    // overflow-y: scroll;
}
.formElement{
    width: 100%;
   display: flex;
   gap: 20px;
   margin: 2% 0;


}
.greenConcept{
    background-color:green;
    color:white
}
.formElementGrade{
    width: 20%;

}
.formElementTopic{
    width: 60%;
}
.formElementStream{
    width:20%;
}
.labelCon{
    margin-bottom: 1% !important; 
    display: flex;
    justify-content: space-between;
    align-items: center;  
}
.labelCon button{
    font-size: 12px;
    
}
.conceptList{
    padding: 0.5% 2%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 1% 2%;
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 8fr 1fr;
    gap: 20px;
    
}
.conceptList p{
    font-size: 14px;
    font-weight: 600;
}
.closeIcon{
    display:flex;
    justify-content: flex-end;
    cursor:pointer;
    color: red;
}
.saveConcepts{
    margin-top: 2%;
    display: flex;
    justify-content: center;
}
.saveConcepts button{
    width: 20%;
    background: #213861;
    font-size: 12px;
    color: white;
    height: 25px;
}
 `;