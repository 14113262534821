import { Input,Select,message } from 'antd'
import React, { useState,useEffect } from 'react'
import { CreateNewUserWrapper } from './CreateNewUser.style';
import SecuredServices from 'api/securedServices';
import AuthServices from 'api/authServices';

export default function CreateNewUser() {
    const [roles,setRoles] = useState([]);
    const[rolesData,setRolesData] = useState([]);
    const [disableSubmit,setDisableSubmit] = useState(false);
    const [userInput,setUserInput] = useState({
        username:"",
        first_name:"",
        last_name:"",
        email:"",
        password:"",
        confirm_password:""
    });


    useEffect(()=>{
        AuthServices.getAllRoles().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                setRolesData(res.data.data);
            }
        })
    },[])
    const [passwordsMatch,setPasswordsMatch] = useState(false);



    const handleSubmit = ()=>{
        console.log(roles);
        setDisableSubmit(true);
        if(userInput.username.length===0 || userInput.first_name.length===0 || userInput.last_name.length===0 || userInput.email.length===0 || userInput.password.length===0 || userInput.confirm_password.length===0){
            message.error("Please fill all mandatory fields");
            return;
        }
        if(userInput.password!==userInput.confirm_password){
            message.error("Password and confirm password should match");
            return;
        }
        if(userInput.password.length<4){
            message.error("Password length should be greater or equal to 4");
        }
        let Apidata = {
            data:userInput,
            roles:roles
        }
        console.log(Apidata);
        SecuredServices.createNewUser(Apidata).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                message.success(res.data.message);
                window.location.reload();
            }
            else{
                message.error(res.data.message);
            }
        })
    }
  return (
    <CreateNewUserWrapper>
        <div className='form'>
            <div className='formElement'>
                <label htmlFor="" className="label"> *Username</label>
                <Input placeholder="Enter Username" onChange={(e)=>setUserInput({...userInput,username:e.target.value})} />
            </div>
            <div className='formElement'>
                <label htmlFor="" className="label">*First Name</label>
                <Input placeholder="Enter First Name" onChange={(e)=>setUserInput({...userInput,first_name:e.target.value})} />
            </div>
            <div className='formElement'>
                <label htmlFor="" className="label">*Last Name</label>
                <Input placeholder="Enter Last Name"  onChange={(e)=>setUserInput({...userInput,last_name:e.target.value})}/>
            </div>

            <div className='formElement'>
                <label htmlFor="" className="label">*Email</label>
                <Input placeholder="Enter Email" onChange={(e)=>setUserInput({...userInput,email:e.target.value})} />
            </div>

            <div className='formElement'>
                <label htmlFor="" className="label">*Password</label>
                <Input.Password placeholder="Enter Password" onChange={(e)=>{
                    setUserInput({...userInput,password:e.target.value})
                    if(e.target.value!==userInput.confirm_password){
                        setPasswordsMatch(false);
                    }
                    else{
                        setPasswordsMatch(true);
                    }
            }} />
            </div>

            <div className='formElement'>
                <label htmlFor="" className="label">*Confirm Password</label>
                <Input.Password placeholder="Enter Confirm Password" onChange={(e)=>{
                    setUserInput({...userInput,confirm_password:e.target.value})
                    if(userInput.password!==e.target.value){
                        setPasswordsMatch(false);
                    }
                    else{
                        setPasswordsMatch(true);
                    }
                    }} />

                <div style={{textAlign:"left",width:"100%"}}>
                    <p style={{color:"red"}}>{passwordsMatch==false && "Passwords does not match."}</p>
                </div>

            </div>


            <div className="formElement">
                <label className='label'>*Roles:</label>
                <Select 
                mode='multiple'
                value={roles}
                style={{width:"100%"}}
                onChange={(value)=>setRoles(value)}
                >
                      {
                       rolesData.map((role)=>{
                           return <Select.Option key={role.roleid} value={role.roleid}>{role.name}</Select.Option>
                       })
                   }
                </Select>
            </div>

            <div className='button'>
                <button className='submitButton' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    </CreateNewUserWrapper>
  )
}
