import React, { useState,useEffect } from 'react'
import { SubmitMissionProgramWrapper } from './SubmitMissionProgram.style';
import { notification, Breadcrumb, Input,Modal,message } from 'antd';
import { useLocation, } from 'react-router-dom';
import Spinner from "assets/images/Spinner.gif";
import SecuredServices from 'api/securedServices';
import GenricModal from 'components/comman/modalComponents/modal/modal';



export default function SubmitMissionProgram() {


  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isRevert = params.get('is_revert');
  const qName = params.get('q_name');
  const topicId = params.get('topic_id');
  const conceptId = params.get('concept_id');
  const [userInputData, setUserInputData] = useState({
    program: null,
    rubrics: null
  })
  const [missionPdfModal, setMissionPdfModal] = useState(false);
  const [blockSubmitAndTest, setBlockSubmitAndTest] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);


  const handleSubmit  = ()=>{
    // if(isSubmit==false){
    //   setBlockSubmitAndTest(true);
    // }
    console.log(userInputData);
    setBlockSubmitAndTest(true);
    setDisableSubmit(true);
    notification.success({
      message: 'Submitting the Program',
      description: 'Submitting the Program takes a little while as it goes through some validations, please wait for some time. If any error occurs, Please fix the Program and submit again.',
      duration: 15,
      showProgress: true,
      pauseOnHover: true,
      placement: 'bottomRight',
    });
   
    let data = userInputData;
    let flag =1;
    if(data.program==null || data.rubrics==null){
        message.error("Please fill all the required(*) fields");
        setDisableSubmit(false);
        setBlockSubmitAndTest(false);
        flag=0;
    }

    // console.log(validateJson(JSON.stringify(data.rubrics)));

    if(validateJson(data.rubrics)==false){
        message.error("Please enter valid rubrics");
        setDisableSubmit(false);
        setBlockSubmitAndTest(false);
        flag=0;
    }

    if(flag ==1){
      console.log(data);
      let submitdata = {
        topic_id: topicId,
        concept_id:conceptId,
        q_name:qName,
        program:data.program,
        rubrics:JSON.stringify(JSON.parse(data.rubrics)),
      }
      console.log(submitdata);
      SecuredServices.submitMissionProgramAndRubrics(submitdata).then((res)=>{
        console.log(res);
     
        if(res.data.status ==1){
          message.success(res.data.message);
          setDisableSubmit(false);
          setBlockSubmitAndTest(false);
          setMissionPdfModal(true);
            window.history.back();
        }
        else{
          Modal.error({
            title: 'Error',
            content: `${res.data.message} \n ${res.data.error}`
          })
          // message.error(res.data.message);
          setDisableSubmit(false);
          setBlockSubmitAndTest(false);
        }
      })
      .catch((err)=>{
        console.log(err);
        setDisableSubmit(false);
        setBlockSubmitAndTest(false);
      }).finally(()=>{
        setDisableSubmit(false);
        setBlockSubmitAndTest(false);
      })
    }
  }

  useEffect(()=>{
    console.log(isRevert);
    if(isRevert=="true" || isRevert==true){
      console.log(isRevert);
        SecuredServices.getProgramDetailsByQName(qName).then((res)=>{
            console.log(res.data.data);
            if(res.data.status==1){
                setUserInputData({
                    program:res.data.data[0].program,
                    rubrics:JSON.parse(res.data.data[0].rubrics)
                })
                // setUserInputData(res.data.data[0]);
            }
        })
    }
},[isRevert])

  const validateJson = (data)=>{
    try{
        console.log(data);
        JSON.parse(data);
        console.log(data);
        return true;
    }
    catch(err){
      console.log(err)
        return false;
    }
  }
  return (
    <SubmitMissionProgramWrapper>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item onClick={() => { window.history.back() }}>Create Content</Breadcrumb.Item>
        <Breadcrumb.Item>{qName}</Breadcrumb.Item>
      </Breadcrumb>

      <h3>Submit Mission Program</h3>

      <div className='formParent'>
        <div>
          <h2>Submit Mission Program and Rubrics</h2>
        </div>
        <div className='form'>
          <div className='formElement'>
            <label htmlFor="" className='label'>Mission Program:</label>
            <Input.TextArea rows={5}
              placeholder='Enter your Python Mission Program here'
              value={userInputData.program}
              name=""
              onChange={(e) => setUserInputData({ ...userInputData, program: e.target.value })}
            />
          </div>

          <div className='formElement'>
            <label htmlFor="" className='label'>Mission Rubrics ({"Rubrics must be in proper object {key:value} format"}):</label>
            <Input.TextArea rows={5}
              placeholder='Input Mission Rubrics here'
              value={userInputData.rubrics}
              name=""
              onChange={(e) => setUserInputData({ ...userInputData, rubrics: e.target.value })}
            />
          </div>


          {/* <div className={blockSubmitAndTest?"saveAndTestButton block":'saveAndTestButton '} onClick={()=>handleSubmit(false)}>
                <button>{blockSubmitAndTest? <div><img src={Spinner} width={20}/> processing</div>: "Save and Test"} </button>
            </div> */}


            <div className='buttonsSections'>
            <div className='submitButton' onClick={()=>handleSubmit(true)} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
             {blockSubmitAndTest?<div style={{display:"flex",alignItems:"center",gap:"5px"}}><img src={Spinner} width={20}/>processing</div>: "Submit"}
                </div>
            </div>

        </div>
      </div>

      <GenricModal
            closable={true}
            width={700}
            title={"Verify Mission Program"}
            isOpen={missionPdfModal}
            toggle={()=>setMissionPdfModal(false) }
        />

    </SubmitMissionProgramWrapper>
  )
}
