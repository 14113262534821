import styled from "styled-components";

export const VerifyContentWrapper = styled.div`
.verifyContentListParent{
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // height: 55vh;
}
.verifyContentList{
    padding: 2% 3%;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
}
`;