import React,{act, useEffect, useState} from 'react'
import { CreateContentWrapper } from './CreateContent.style'
import { Tabs,message ,Table} from 'antd';

import ContentCard from 'components/comman/contentCard/ContentCard';
import SecuredServices from 'api/securedServices';
import { FilterData, getDate } from 'helper/utils';
import GenricModal from 'components/comman/modalComponents/modal/modal';
import { useNavigate } from 'react-router-dom';
import NoDataFound from 'components/comman/NoDataFoundComponent/NoDataFound';
import { RouteDefinitions } from 'api/routes/routeDefinitions';
import SearchBar from 'components/comman/SearchBar/SearchBar';


const items = [
    {
      key: '1',
      label: 'Pending',
      children: 'Content of Tab Pane 1',
    },
    {
      key: '2',
      label: 'Tab 2',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Tab 3',
      children: 'Content of Tab Pane 3',
    },
  ];
  const columns = [
    {
        title:"qName",
        sorter: (a, b) => a.q_name - b.q_name,
        render: (text, record) => {
            return (
              <span>{record.q_name} {record.auto_generated && parseInt(record.auto_generated)!=0?`(${record.auto_generated})`:""}</span>
            )}

    },
    {
        title:"Grade",
        dataIndex:"grade_id",
        sorter: (a, b) => a.grade_id - b.grade_id,
    },
    {
        title:"Topic",
        dataIndex:"topic_name",
        sorter: (a, b) => a.topic_name - b.topic_name,
    },
    {
        title:"Concept",
        dataIndex:"concept_name",
        sorter: (a, b) => a.concept_name - b.concept_name,
    },
    {
        title:"Q-tex",
        render :(text,record,index)=>{
            return <a 
            href={record.q_tex_link}
             download={"q_tex.text"} >download</a>
        }
    },
    {
        title:"A-tex",
        render :(text,record,index)=>{
            return <a 
             href={record.a_tex_link} 
             download={"a_tex.text"}>download</a>
        }
    },
    {
        title:"mcq-json",
        render :(text,record,index)=>{
            return <div style={{width:"100%"}}>
                            <a style={{display:record.type=="Descriptive"?"none":"inline"}} href={record.mcq_json_link} download={"mcq_json.text"}>download</a>
            </div>
        }
        },
    {
        title:"iq-annotation",
        render :(text,record,index)=>{
            return <a href={record.iq_annotation_link} download={"iq_annotation.text"}>download</a>
        }
    },

    {
        title:"Q Type",
        dataIndex:"type"

    },
    {
        title:"Status",
        dataIndex:"status",
        sorter: (a, b) => a.status - b.status,
    },
    {
        title:"date",
        render:(text,record,index)=>{
            return <p>{getDate(record.createdAt)}</p>
        },
        sorter: (a, b) => a.createdAt - b.createdAt,
    },
   
]


export default function CreateContent() {
    const [activeKey,setActiveKey] = useState("1");
    const [contentList,setContentList] = useState([]);
    const [baseContentList,setBaseContentList] = useState([]);
    const [userContentList,setUserContentList] = useState([]);
    const [baseUserContentList,setBaseUserContentList] = useState([]);
    const [questionDetailsModal,setQuestionDetailsModal] = useState(false);
    const [feedBackDetailsModal,setFeedBackDetailsModal] = useState(false);
    const [selectedCard,setSelectedCard] = useState({});
    const [dataSource,setDataSource] = useState([]);
    const [cmsAdminToken,setCmsAdminToken] = useState("");
    const [searchQuery,setSearchQuery] = useState("");

    const Navigate = useNavigate();

    useEffect(()=>{
        console.log(selectedCard);
        if(activeKey=="1"){
            getALLquestions();
        } 
        else if(activeKey=="2"){
            getUserquestions();
        }
        else if(activeKey=="3"){

            SecuredServices.getProgramerSubmittedQuestion().then((res)=>{
                console.log(res.data);
                if(res.data.status==1){
                    console.log(res.data.data);
                    let submittedData =res.data.data;
                    if(submittedData.length>0){
                        submittedData.forEach((data)=>{
                            const blob1 = new Blob([data.q_tex], { type: 'text/plain' });
                            const blob2 = new Blob([data.a_tex], { type: 'text/plain' });
                            const blob3 = new Blob([data.iq_annotation], { type: 'text/plain' });
                            const  url1 = URL.createObjectURL(blob1);
                            const  url2 = URL.createObjectURL(blob2);
                            const  url3 = URL.createObjectURL(blob3);
                            data.q_tex_link = url1;
                            data.a_tex_link = url2;
                            data.iq_annotation_link = url3;
                            console.log(data);
                        })
                    }
                    setDataSource(submittedData);
                }
            })
        }
        
    },[activeKey])


    const getALLquestions = ()=>{
        SecuredServices.getAllPendingQuestions().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                setContentList(res.data.data);
                setBaseContentList(res.data.data);
            }
        }).catch((err)=>{
            console.log(err);
        })
    }


    const getUserquestions = ()=>{
        SecuredServices.getUserPendingQuestions().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                setCmsAdminToken(res.data.data[0]?.token);
                setUserContentList(res.data.data);
                setBaseUserContentList(res.data.data);
            }
        }).catch((err)=>{
            console.log(err);
        })
    }


    const assignProgramToUser = (index)=>{
        console.log(index);
        let data = {
            q_name:contentList[index].q_name,
            creator_queue_id:contentList[index].creator_queue_id
        }
        SecuredServices.postAssignQuestionToUser(data).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                message.success(res.data.message);
                getALLquestions();
            }
            else{
                message.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
            message.error(err.message);
        })
    }

    const handleSubmitFilesClick =(q_name)=>{
        console.log(q_name);
        if(selectedCard.content_type=="mission" && selectedCard.type=="program"){
            Navigate(`${RouteDefinitions.ROUTE_SUBMIT_MISSION_PROGRAM}?q_name=${selectedCard.q_name}&topic_id=${selectedCard.topic_id}&concept_id=${selectedCard.concept_id}&is_revert=${selectedCard.status=="reverted"?true:false}`);
        }
        else if(selectedCard.content_type=="iq" && selectedCard.type=="exact"){
            Navigate(`/content/submit-exact?q_name=${q_name}&topic_id=${selectedCard.topic_id}&grade=${selectedCard.grade_id}&topic=${selectedCard.topic_name}&type=${selectedCard.content_type}&creator_queue_id=${selectedCard.creator_queue_id}&q_id=${selectedCard.q_id}&token=${cmsAdminToken}`);            
        }
        else if(selectedCard.content_type=="mcq" && selectedCard.type=="exact"){
            console.log(`${RouteDefinitions.ROUTE_SUBMIT_MCQ_PROGRAM}?q_name=${q_name}&topic_id=${selectedCard.topic_id}&concept_id=${selectedCard.concept_id}&is_revert=${selectedCard.status=="reverted"?true:false}`);
            Navigate(`${RouteDefinitions.ROUTE_SUBMIT_MCQ_PROGRAM}?q_name=${q_name}&topic_id=${selectedCard.topic_id}&concept_id=${selectedCard.concept_id}&is_revert=${selectedCard.status=="reverted"?true:false}`);
        }
    }

    const deAssignProgramToUser =(index)=>{
        console.log(index);
        let data = {
            creator_queue_id:userContentList[index].creator_queue_id
        }
        SecuredServices.postDeAssignQuestionFromUser(data).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                message.success(res.data.message);
                getUserquestions();
            }
            else{
                message.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
            message.error(err.message);
        })
    }

    const OnClickOnFeedback = (item)=>{
        console.log(item);
        item.token = cmsAdminToken;
        setSelectedCard(item);
        setFeedBackDetailsModal(true);
    }

    const handleSearchBarChange = (filteredData)=>{
        setContentList(filteredData);
    }

    const handleSearchBarChangeForUser = (filteredData)=>{
        setUserContentList(filteredData);
    }

 


  return (
    <CreateContentWrapper>
        <div>
        <h3>Create Content</h3>
        <div >
            <Tabs defaultActiveKey="1" 
            onChange={(key)=>{
                setSearchQuery("");
                setActiveKey(key)}}
             >
                <Tabs.TabPane tab="Pending" key="1">
                <div className='createContentListParent'>
                  <SearchBar data={baseContentList} setFilteredData={handleSearchBarChange} />
                {contentList.length==0?<NoDataFound/>:
                  <div className='createContentList'>
                  {  
                      contentList.map((item,index)=>{
                          return <ContentCard 
                          key={index}
                          auto_generated={item.auto_generated}
                          cardHeader={item.q_name} 
                          tag1  = {item.content_type}
                          tag2={item.type}
                          topic={item.topic_name}
                          concept={item.concept_name}
                          date={getDate(item.updatedAt)}
                          buttonType={1}
                          handleButtonClick={ ()=>assignProgramToUser(index) }
                          />
                      })
                  }
                {/* <ContentCard 
                cardHeader={"playNumbers1-natural-Q1"} 
                tag2={"MCQ"}
                tag1={"rubrics"}
                topic={"Natural Numbers"}
                concept={"Number with no zero"}
                date={"10/10/2022"}
                buttonType={1}
                /> */}
              </div>
                }
              
            </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Assigned" key="2">
                    <div className='createContentListParent'>
                    <SearchBar data={baseUserContentList} setFilteredData={handleSearchBarChangeForUser} />
                        { 
                        userContentList.length==0?<NoDataFound/>:
                        <div className='createContentList'>
                        {
                            userContentList.map((item,index)=>{
                                console.log(item);
                                return <ContentCard 
                                key={index}
                                onClickOnCard = {()=>{
                                    setSelectedCard(item);
                                    setQuestionDetailsModal(true)}}
                                cardHeader={item.q_name} 
                                auto_generated={item.auto_generated}
                                status = {item.status}
                                tag1  = {item.content_type}
                                tag2={item.type}
                                topic={item.topic_name}
                                concept={item.concept_name}
                                date={getDate(item.updatedAt)}
                                buttonType={2}
                                handleButtonClick={ ()=>deAssignProgramToUser(index) }
                                onClickOnFeedback={()=>{
                                    console.log("click happend on the feedback");
                                    OnClickOnFeedback(item)}}
                                />
                            })
                        }
                      {/* <ContentCard 
                      cardHeader={"playNumbers1-natural-Q1"} 
                      tag2={"MCQ"}
                      tag1={"rubrics"}
                      topic={"Natural Numbers"}
                      concept={"Number with no zero"}
                      date={"10/10/2022"}
                      buttonType={2}
                      /> */}
                    </div>
                        }
                   
                    </div>
               
                </Tabs.TabPane>
                <Tabs.TabPane tab="Submitted" key="3">
                     <div>
                     <Table dataSource={dataSource} columns={columns} />
                     </div>
                </Tabs.TabPane>
                </Tabs>
        </div>
          
        </div>

        <GenricModal 
        title="Question Details" 
        isOpen={questionDetailsModal} 
        toggle={()=>setQuestionDetailsModal(false)}
        width={900}
        data={selectedCard}
        onSubmitClick={handleSubmitFilesClick}
         />

<GenricModal 
        title="Verifier Feedback" 
        isOpen={feedBackDetailsModal} 
        toggle={()=>setFeedBackDetailsModal(false)}
        width={600}
        data={selectedCard}
         />
      
    </CreateContentWrapper>
  )
}
