import React ,{useState}from 'react'
import { TestMcqInstanceWrapper } from './TestMcqInstance.style'
import { Breadcrumb } from 'antd'

export default function TestMcqInstance({qName}) {

    const [data,setData] = useState([
          {
            "question": "The zeroes of x² – 2x – 8 are:",
            "options": {
              "a": "(2, -4)",
              "b": "(4, -2)",
              "c": "(-2, -2)",
              "d": "(-4, -4)"
            },
            "answer": "b",
            "explanation": "x² – 2x – 8 = x² – 4x + 2x – 8 = x(x – 4) + 2(x – 4) = (x - 4)(x + 2). Therefore, x = 4, -2."
          },
          {
            "question": "What is the quadratic polynomial whose sum and the product of zeroes is √2, ⅓ respectively?",
            "options": {
              "a": "3x² - 3√2x + 1",
              "b": "3x² + 3√2x + 1",
              "c": "3x² + 3√2x - 1",
              "d": "None of the above"
            },
            "answer": "a",
            "explanation": "Sum of zeroes = α + β = √2. Product of zeroes = αβ = 1/3. If α and β are zeroes of any quadratic polynomial, then the polynomial is x² – (α + β)x + αβ = x² – (√2)x + (1/3) = 3x² - 3√2x + 1."
          },
          {
            "question": "If the zeroes of the quadratic polynomial ax² + bx + c, c ≠ 0 are equal, then:",
            "options": {
              "a": "c and b have opposite signs",
              "b": "c and a have opposite signs",
              "c": "c and b have same signs",
              "d": "c and a have same signs"
            },
            "answer": "d",
            "explanation": "For equal roots, the discriminant will be equal to zero: b² - 4ac = 0. Therefore, b² = 4ac, and ac > 0 (since the square of any number cannot be negative)."
          },
          {
            "question": "The degree of the polynomial x⁴ – x² + 2 is:",
            "options": {
              "a": "2",
              "b": "4",
              "c": "1",
              "d": "0"
            },
            "answer": "b",
            "explanation": "Degree is the highest power of the variable in any polynomial."
          },
          {
            "question": "If one of the zeroes of cubic polynomial x³ + ax² + bx + c is -1, then the product of the other two zeroes is:",
            "options": {
              "a": "b - a - 1",
              "b": "b - a + 1",
              "c": "a - b + 1",
              "d": "a - b - 1"
            },
            "answer": "b",
            "explanation": "Since one zero is -1, the product of the other two zeroes is b - a + 1."
          }
        ]
      )
  return (
    <TestMcqInstanceWrapper>
        <div>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item onClick={()=>{window.history.back()}}>Submit Mcq Instance</Breadcrumb.Item>
          <Breadcrumb.Item>{qName}</Breadcrumb.Item>
         </Breadcrumb>        </div>
        <div className='outerCard'>
            <h3>Test MCQ Instance</h3>
            <div className='listParent'>
                {
                    data?.map((item,index)=>{
                        return(
                            <div className='card'>
                               <div className='heading'>
                                <p>Q_name : topic325_user5_q29</p>
                                <p>Question Number : {index+1}</p>
                               </div>
                               <div className='question'>
                                    <h5>Question :</h5>
                                    <p>{item?.question}</p>
                               </div>
                               <div className='options'>
                                    <h5>Options :</h5>
                                    <div className='optionsDiv'>
                                    <p className='option'>A: {item?.options?.a}</p>
                                    <p className='option'>B: {item?.options?.b}</p>
                                    <p className='option'>C: {item?.options?.c}</p>
                                    <p className='option'>D: {item?.options?.d}</p>
                                    </div>
                               </div>
                               <div className='correctAns'>
                                    <h5>Correct Answer :</h5>
                                    <p>{item?.answer.toUpperCase()} ({item?.options[item?.answer]})</p>
                               </div>
                               <div className="explaination">
                                <p>Explanation : {item?.explanation}</p>
                               </div>
                            </div>
                        )
                    })
                }
               
            </div>
        </div>
    </TestMcqInstanceWrapper>
  )
}
