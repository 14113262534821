import {
    store
  } from "../redux/store";
  import axios from "axios";
  
  class Headers {
    static setHeaders = () => {
      console.log(store.getState());
      // console.log(localStorage.getItem("auth"));
      axios.defaults.headers.common[
        "Authorization"
      ] = store.getState().user.token;
    };
  
  
    static setHeadersForMobileFromLocalStorage = (token) => {
  
      if(token && token!=null && token !=""){
        axios.defaults.headers.common[
          "Authorization"
        ] = token;
      }
      else{
        axios.defaults.headers.common[
          "Authorization"
        ] = localStorage.getItem("token");
      }
     
     
    };
  
  
  
  
  
    static setAdminHeaders = () => {
      axios.defaults.headers.common[
        "Authorization"
      ] = store.getState().auth.token;
    };
  }
  
  export default Headers;