// export const Url = "https://api.3.108.0.123.nip.io/";
// export const Url = process.env.REACT_APP_BASE_URL;
// export const Url = "http://localhost:4000";
export const Url = "https://cmsapi.3.108.0.123.nip.io";
// 
export const IqLink = "https://website.3.108.0.123.nip.io/";
// export const IqLink = "http://localhost:3000/";

const size = {
  mobileS: "321px",
  mobileM: "376px",
  mobileL: "500px",
  tablet: "768px",
  tabletL:"991px",
  laptop: "1025px",
  laptopL: "1441px",
  desktop: "2561px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
