import React, { useState } from 'react'
import { TextInputModalWrapper } from './TextInputModal.style'
import { Button, Input,Slider } from 'antd'

export default function TextInputModal({data,onOkClick,onCancel}) {
    const [inputData, setInputData] = useState(data!=null?data?.text:"");
    const [fontSize,setFontSize] = useState(data!=null?data?.fontSize:24);

  return (
    <TextInputModalWrapper>
        <Input.TextArea placeholder="Enter Text" value={inputData} onChange={(e) => setInputData(e.target.value)} />
        <Slider 
        min={12}
        max ={100}
        defaultValue={24}
        // tooltip={{
        //     open:true
        // }}
        value={fontSize}
        onChange={(value)=>setFontSize(value)}
        onChangeComplete={(value)=>setFontSize(value)}
        />
        <div className='sliderRange'>
            <span>12</span>
         <span>100</span>
        </div>
        
      
        <div className='buttonsSections'>
        <Button onClick={() => onOkClick({text:inputData,fontSize:fontSize})} type="primary">{data && data.text!=""?"Update":"Add Text"}</Button>

        <Button onClick={onCancel} danger>Cancel</Button>
        </div>
    </TextInputModalWrapper>
  )
}
