import styled from "styled-components";
// import Paper4 from "assets/images/Paper4.jpg";
import {
  device
} from "helper/constants";

const AssigmentDetailsWrapper = styled.div `
  min-width: 100%;
  margin-top: -20px;
  position: relative;

  .question {
    & :hover {
      background-color: red;
    }
  }

  .imageLeft{

  }
  .acceptVerificationButton{
    padding: 0 2%;
    background: #213861;
    color: white;
    border-radius: 5px;
    width: 110px;
    border:none;
    height:30px;
  }
  .rejectVerificationButton{
    padding: 0 2%;
    background: red;
    color: white;
    border-radius: 5px;
    width: 110px;
    border:none;
    height:30px;
  }
  .rotate {
   
    background: #000000;
    color: #ffffff;
    padding: 3px 8px;
    border-radius: 8px;
    z-index: 5;
    cursor:pointer;
  }


    .clearAll-rub{

    bottom: 10px;
    background: black;
    color: #ffffff;
    // padding: 3px 5px;
    border-radius: 8px;
    z-index: 5;
     width: 50px;
    font-size: 13px;
    text-align: center;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select:none;
    height:30px;

  }
  .undo-rub{
   background: black;
   color: #ffffff;
   // padding: 3px 8px;
   border-radius: 8px;
   z-index: 5;
    width: 50px;
   font-size: 13px;
   text-align: center;
   cursor:pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   user-select:none;
   height:30px;
 }
 .text-rub{
  background: black;
    color: #ffffff;
    // padding: 3px 8px;
    border-radius: 8px;
    z-index: 5;
    width: 50px;
    font-size: 13px;
    text-align: center;
    height:30px;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select:none;
  }


   .right-rub{
    background: black;
    color: #ffffff;
    // padding: 3px 8px;
    border-radius: 8px;
    z-index: 5;
    width: 50px;
    font-size: 16px;
    text-align: center;
    height:30px;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select:none;

  }
   .wrong-rub{
  
    background: black;
    color: #ffffff;
    // padding: 3px 8px;
    border-radius: 8px;
    z-index: 5;
     width: 50px;
    font-size: 16px;
    text-align: center;
    height:30px;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle-rub{
    background: black;
    color: #ffffff;
    border-radius: 8px;
    z-index: 5;
    width: 50px;
    font-size: 16px;
    text-align: center;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
  }
  .highlightButton1{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    transform: scale(1.01) perspective(1px);
    background:green;
  }
   .highlightButton2{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    transform: scale(1.01) perspective(1px);
    background:red;
  }
  .highlightButton3{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    transform: scale(1.01) perspective(1px);
    background:darkorange;
  }
  .highlightButton4{
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    transform: scale(1.01) perspective(1px);
    background:blue;
  }
  .card {
    background-color: #f7f7f7;
  }

  .ant-divider-horizontal {
    border-top: 1px solid #d9e1e8;
    margin: 0;
  }

  
  .ant-divider-vertical {
    border-left: 1px solid #d9e1e8;
    margin: 0;
    display: block;
    height: 100%;
  }

  .pdfImagesDiv{
    width: 70%;
    display: flex;
    justify-content: center;
    margin: 5px;
  }
  .commentBox{
    margin: 5% auto 0;
    width: 80%;
  }
  .paginationAndEditButtons{
    display: flex;
    justify-content: space-between;
    padding: 0 2%;
  }

  .grid {

    height: 85vh;
    /* justify-items: center; */
    margin: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .editButtons{
    display: flex;
    gap: 10px;
    justify-content: center;
  }

  .grid-old {
    display: grid;
    width:100%;
    grid-template-columns: 54% 0% 46%;
    /* justify-items: center; */
  }

  .grid-3 {
    height: calc(100vh - 200px);
    /* overflow: auto; */
    display: grid;
    grid-template-columns: 10% 90%;
    grid-column-gap: 10px;
  }
  .grid-3-old {
    height:80vh;
    width:100%;
    /* overflow: auto; */
    display: grid;
    grid-template-columns: 10% 90%;
    grid-column-gap: 10px;
  }

  .grid-4 {
    display: grid;
    grid-template-columns: 30% 70%;
    justify-items: center;
    padding-top: 10px;
    grid-row-gap: 10px;
  }

  .circle {
    width: 24px;
    height: 24px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    margin: auto;
  }

  .ant-radio-inner {
    width: 24px;
    height: 24px;
  }

  .green {
    .ant-radio-inner {
      border-width: 3px;
      border-radius: 50%;
      border-style: solid;
      border-color: #44cb54;
    }

    .ant-radio-checked .ant-radio-inner {
      background: #44cb54 !important ;
    }
    .ant-radio-checked .ant-radio-inner:after {
      background: none;
    }
  }

  .yellow {
    .ant-radio-inner {
      border-width: 3px;
      border-radius: 50%;
      border-style: solid;
      border-color: #d5ca52;
    }

    .ant-radio-checked .ant-radio-inner {
      background: #d5ca52 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background: none;
    }
  }

  .orange {
    .ant-radio-inner {
      border-width: 3px;
      border-radius: 50%;
      border-style: solid;
      border-color: #ea9f28;
    }

    .ant-radio-checked .ant-radio-inner {
      background: #ea9f28 !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background: none;
    }
  }

  .red {
    .ant-radio-inner {
      border-width: 3px;
      border-radius: 50%;
      border-style: solid;
      border-color: #cc2d2d;
    }

    .ant-radio-checked .ant-radio-inner {
      background: #cc2d2d !important ;
    }

    .ant-radio-checked .ant-radio-inner:after {
      background: none;
    }
  }

  /* .ant-radio-checked .ant-radio-inner {
    border-color: red !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: red;
    transform: scale(2.5);
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: red;
  } */

  .background {
    background: linear-gradient(
      to bottom,
      #000000,
      rgba(0, 0, 0, 0.4) 57%,
      rgba(255, 255, 255, 0) 112%
    );

    width: 49%;
    height: 70px;
    padding: 10px;
    position: absolute;
  }

  .pdf-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  .pdf-page {
    margin: 0 auto;
  }
  .konvajs-content{
    overflow-x:scroll;
  }
  // .konvajs-content canvas{
  //   width: 100% !important;
  //   height: 100% !important;
  // }

  .pdf-hover {
    width: 30%;
    color: white;
    float: right;
    border: 1px solid white;
    font-size: 12px;
    font-family: "Montserrat-SemiBold";
    text-align: center;
    border-radius: 5px;
    padding: 5px;
  }

  iframe {
    scrollbar-face-color: #ff8c00;
    scrollbar-track-color: #fff8dc;
    scrollbar-arrow-color: #ffffff;
    scrollbar-highlight-color: #fff8dc;
    scrollbar-shadow-color: #d2691e;
    scrollbar-3dlight-color: #ffebcd;
    scrollbar-darkshadow-color: #8b0000;
  }

  /* body.embed {
    margin: 0 !important;
    overflow: hidden !important;
    background: none !important;
  } */

  /* #scroller::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  #scroller::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  } */

  /* .relative::-webkit-scrollbar {
    width: 11px;
  }
  .relative {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  .relative::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  .relative::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  } */

  .grid-2 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-column-gap: 5px;
  }



  .ant-btn-primary {
    background: #658bb1;
    border-color: #658bb1;
  }
  .__markerjs2_ {
    font-size: 16px;
    position: absolute;
    top: 167px !important;
    height: 500px !important;
    left: 510px !important;
    width: 390px;
    z-index: 5;
    overflow: scroll;
  }
  .markerjs2-collapse{
    font-size: 16px;
    position: absolute;
    top: 121px !important;
    left: 104px !important;
    height: 500px !important;
    width: 500px;
    z-index: 5;
    overflow: scroll;
  }

.backForwardButtonsDiv{
  display:flex;
  justify-content:space-between;
  position:absolute; 
  top: 50%; 
  width: 100%;
  height:1px;
}
.backForwardButtonsDivChild{
    font-size: 40px;
    background-color: gray;
    color: white;
    border-radius: 50%;
    opacity: 0.5;
    cursor:pointer;
    position: absolute;
    
}

.right{
  right:0;
}
.left{
  left:0;
}

.unAssignDiv{
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  background: #f2f2f2;
  border: 1px solid black;
  min-width: 50px;
  height: 50px !important;
  color: white;
  font-weight: bolder;
  border-radius: 50%;
  height: 100%;
  cursor:pointer;
  font-size: 22px;
  padding: 1%;
}

@media ${device.tabletL} {
  .pdfImagesDiv{
    width: 80%;
  }
  .commentBox{
    margin: 5% auto 0;
    width: 95%;
  }

}

@media ${device.tablet} {
  .paginationAndEditButtons{
    display: flex;
   flex-direction: column;
   gap: 20px;
   justify-content: start;
    padding: 0 2%;
  }
}

@media ${device.mobileL} {
  .grid {

   
    margin: 0 0%;
  }

 .pdfImagesDiv {
    width: 100vw;
    min-width:300px;
    height:85vh !important;
    margin: 0;
}
.imageClass{
  padding-left:100px;
  margin:20%;
  scale:0.5;
}
.konvajs-content{
  // margin-left:10%;
  // margin-right:10%;
  transform:scale(0.8);
}
.konvajs-content canvas{
  
}


       
        .rotate{
          position: absolute;
          left: 10px;
          bottom: 10px;
          background: #000000;
          color: #ffffff;
          padding: 3px 8px;
          border-radius: 8px;
          z-index: 5;
          cursor: pointer;
          font-size: 11px;
        }
        .right-rub{
          left: 72px;
          bottom: 10px;
          background: black;
          color: #ffffff;
          border-radius: 8px;
          z-index: 5;
          width: 50px;
          font-size: 16px;
          text-align: center;
          height: 23px;
          cursor: pointer;
          font-size: 10px;
          /* padding-bottom: 2px; */
          padding-top: -6px;
        }
        .text-rub{
          left: 72px;
          bottom: 10px;
          background: black;
          color: #ffffff;
          border-radius: 8px;
          z-index: 5;
          width: 50px;
          font-size: 16px;
          text-align: center;
          height: 23px;
          cursor: pointer;
          font-size: 10px;
          /* padding-bottom: 2px; */
          padding-top: -6px;
        }
        .wrong-rub{
          left: 115px;
          bottom: 10px;
          background: black;
          color: #ffffff;
          border-radius: 8px;
          z-index: 5;
          width: 50px;
          font-size: 16px;
          text-align: center;
          height: 23px;
          cursor: pointer;
        }
        .circle-rub{
          left: 159px;
          bottom: 10px;
          background: black;
          color: #ffffff;
          border-radius: 8px;
          z-index: 5;
          width: 50px;
          font-size: 13px;
          text-align: center;
          height: 23px;
          padding-top: 3px;
          cursor: pointer;
        }
        .undo-rub{
         
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
         
          background: black;
          color: #ffffff;
          border-radius: 8px;
          z-index: 5;
          width: 42px;
          font-size: 12px;
          text-align: center;
          height: 23px;
          padding-top: 3px;
          cursor: pointer;
          }


          .clearAll-rub{

          left: 247px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          bottom: 10px;
          background: black;
          color: #ffffff;
          border-radius: 8px;
          z-index: 5;
          width: 57px;
          font-size: 11px;
          height: 23px;
          padding-top: 3px;
          cursor: pointer;
          text-align: center;
          }

          .markerjs2-collapse {
            font-size: 16px;
            position: absolute;
            top: 130px !important;
            height: 480px !important;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            left: 4% !important;
            width: 70% !important;
            z-index: 5;
            background-color: white;
            overflow: auto;
            resize: both;
            
          }

          .rubricsMobileBackgroundDiv{
            max-height: 35%;
          border-radius: 10px;
          overflow-y: scroll;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 5px;
          background-color: #658bb1;
          box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);   
        }
          //staring of the non rubric one style

          .grid-old{
            width: 100%;
            height: 100vh;
            display:unset;
          }
          .grid-3-old{
            height: 55%;
            width: 100%;
            display:block;  
          }


          .circle{
            width: 24px;
            height: 24px;
            text-align: center;
            font-weight: 500;
            font-size: 20px;
            margin: auto;
          }

          .green {
            .ant-radio-inner {
              border-width: 3px;
              border-radius: 50%;
              border-style: solid;
              border-color: #44cb54;
              width:20px;
              height:20px;
            }

            .ant-radio-checked .ant-radio-inner {
              background: #44cb54 !important ;
            }
            .ant-radio-checked .ant-radio-inner:after {
              background: none;
            }
          }

          .yellow {
            .ant-radio-inner {
              border-width: 3px;
              border-radius: 50%;
              border-style: solid;
              border-color: #d5ca52;
              width:20px;
              height:20px;
            }

            .ant-radio-checked .ant-radio-inner {
              background: #d5ca52 !important ;
            }

            .ant-radio-checked .ant-radio-inner:after {
              background: none;
            }
          }

          .orange {
            .ant-radio-inner {
              border-width: 3px;
              border-radius: 50%;
              border-style: solid;
              border-color: #ea9f28;
              width:20px;
              height:20px;
            }

            .ant-radio-checked .ant-radio-inner {
              background: #ea9f28 !important ;
            }

            .ant-radio-checked .ant-radio-inner:after {
              background: none;
            }
          }

          .red {
            .ant-radio-inner {
              border-width: 3px;
              border-radius: 50%;
              border-style: solid;
              border-color: #cc2d2d;
              width:20px;
              height:20px;
            }

            .ant-radio-checked .ant-radio-inner {
              background: #cc2d2d !important ;
            }

            .ant-radio-checked .ant-radio-inner:after {
              background: none;
            }
          
        }

        .rotate-old {
          font-size: 11px;
          position: absolute;
          left: 36px;
          bottom: 10px;
          background: #000000;
          color: #ffffff;
          padding: 3px 8px;
          border-radius: 8px;
          z-index: 5;
          cursor: pointer;
        }
        .right-old {
        position: absolute;
        left: 96px;
        bottom: 10px;
        background: black;
        color: #ffffff;
        border-radius: 8px;
        z-index: 5;
        width: 38px;
        font-size: 12px;
        text-align: center;
        height: 23px;
        cursor: pointer;
        }
        .wrong-old{
          position: absolute;
          left: 137px;
          bottom: 10px;
          background: black;
          color: #ffffff;
          border-radius: 8px;
          z-index: 5;
          width: 35px;
          font-size: 11px;
          text-align: center;
          height: 24px;
          cursor: pointer;

        }
        .circle-old{
        position: absolute;
        left: 175px;
        bottom: 10px;
        background: black;
        color: #ffffff;
        border-radius: 8px;
        z-index: 5;
        width: 35px;
        font-size: 12px;
        text-align: center;
        height: 24px;
        padding-top: 3px;
        }

        .undo-old{
        position: absolute;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        left: 212px;
        bottom: 10px;
        background: black;
        color: #ffffff;
        border-radius: 8px;
        z-index: 5;
        width: 40px;
        font-size: 12px;
        text-align: center;
        height: 24px;
        padding-top: 3px;
        cursor: pointer;
        }
        .clearAll-old{
          position: absolute;
            left: 254px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            bottom: 10px;
            background: black;
            color: #ffffff;
            border-radius: 8px;
            z-index: 5;
            width: 60px;
            font-size: 12px;
            text-align: center;
            height: 24px;
            padding-top: 3px;
            cursor: pointer;
        }

        .ant-pagination-item {
          display: inline-block;
          min-width: 24px;
          height: 24px;
          margin-right: 8px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
          line-height: 24px;
          text-align: center;
          vertical-align: middle;
          list-style: none;
          background-color: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          outline: 0;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
      }
      .ant-pagination-prev, .ant-pagination-next {
          display: inline-block;
          min-width: 24px;
          height: 24px;
          margin-right: 8px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
          line-height: 22px;
          text-align: center;
          vertical-align: middle;
          list-style: none;
          background-color: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          outline: 0;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
      }

      .highlightButton1{
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        transform: scale(1.01) perspective(1px);
        background:green;
      }
       .highlightButton2{
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        transform: scale(1.01) perspective(1px);
        background:red;
      }
      .highlightButton3{
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        transform: scale(1.01) perspective(1px);
        background:darkorange;
      }

  }

`;

export default AssigmentDetailsWrapper;