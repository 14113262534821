import  styled  from "styled-components";


export const ContentCardWrapper = styled.div`
.card{
    // max-width: 15rem;
    border: 1px solid #BEBCBC;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    position: relative;
    padding: 2% 4%;
    margin-bottom: 3%;
}
.revertedCard{
    border: 1px solid #BEBCBC;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    position: relative;
    padding: 2% 4%;
    margin-bottom: 3%;
    background:#ffe6e6;
}

.tag1{
    position: absolute;
    top: -11px;
    left: 12px;
    background: #57BE27;
    padding: 1% 4%;
    /* height: 22px; */
    /* width: 46px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    font-size: 10px;
}
.tag1-alt{
    background: #213861;
    color: white;
    position: absolute;
    top: -11px;
    left: 12px;
   
    padding: 1% 4%;
    /* height: 22px; */
    /* width: 46px; */
    display: flex;
    align-items: center;
    justify-content: center;
   
    border-radius: 5px;
    font-size: 10px;
}
.tag2{
    position: absolute;
    top: -11px;
    right: 12px;
    background: #FAD5BB;
    padding: 1% 4%;
    /* height: 22px; */
    /* width: 46px; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FD8D27;
    border-radius: 5px;
    font-size: 10px;
}

.tag2-alt{
    background: #686868;
    color: white;
    position: absolute;
    top: -11px;
    right: 12px;
    padding: 1% 4%;
    /* height: 22px; */
    /* width: 46px; */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 10px; 
}
.cardHeader{
    border-bottom: 1px solid #213861;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 5%;
    font-size: 14px;
    cursor: pointer;
}
.cardHeader p{
    text-align: center;
    font-weight: bold;
    color: #213861;
}

.reverted{
    color :#E02C37;
}
.feedback{
    border: none;
    background: #213861;
    color: white;
    border-radius: 5px;
    padding: 1% 7%;
    font-size: 10px;
    cursor: pointer;
}

.topicAndConcept{
    font-size: 12px;
    color: #213861;
    padding: 5% 0%;
    font-weight: 500;
}
.dateAndButton{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dateAndButton img{
    cursor: pointer;
}

.date{
    font-size: 10px;
    color: gray;
}
.button{

}
`;