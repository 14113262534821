import { pdfjs } from "react-pdf";
// import * as pdfjs from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export function getDate(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-indexed
    const day = ('0' + date.getDate()).slice(-2);
  
    return `${year}/${month}/${day}`;
  }


  const readFileData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  
  //param: file -> the input file (e.g. event.target.files[0])
  //return: images -> an array of images encoded in base64

export const convertPdfToImages = async (file, isUrl = false,isMobile=false) => {
  try {
    console.log(file);
    let data;

    if (isUrl === true) {
      const resp = await fetch(file);
      console.log(resp);
      data = await resp.arrayBuffer(); // Directly use the ArrayBuffer for URLs
    } else {
      data = await readFileData(file); // Read local file as ArrayBuffer
    }

    console.log(data);
    console.log(`Type of data: ${typeof data}`);
    const firstBytes = new Uint8Array(data, 0, 4);
    console.log('First bytes of the ArrayBuffer:', firstBytes);

    // Check for valid PDF file signature
    if (firstBytes[0] !== 0x25 || firstBytes[1] !== 0x50 || firstBytes[2] !== 0x44 || firstBytes[3] !== 0x46) {
      throw new Error('Invalid PDF file');
    }

    const images = [];
    const pdf = await pdfjs.getDocument({ data }).promise;

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    // Set a higher scale for better quality
    const scale = 2;  // 2x for better quality, increase if needed for higher DPI
  
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: scale ,offsetX:1}); // Use higher scale for higher quality

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport
      };

      await page.render(renderContext).promise;

      // Save the rendered page as a Base64 image (data URL)
      const imageDataUrl = canvas.toDataURL("image/png"); // "image/png" ensures better quality than "image/jpeg"
      images.push(imageDataUrl);
    }

    canvas.remove(); // Cleanup the canvas after rendering

    return images; // Return an array of image data URLs
  } catch (error) {
    console.error('Error generating images from PDF:', error);
    throw error;
  }
};



  //this function is used to filter the data based on the search query
  //param: search -> the search query
  //param: searchList -> the list of data to be filtered
  //return: filteredList -> the filtered list
  export const FilterData = ({ search, searchList }) => {
    let searchQuery = search.toLowerCase(); // Ensure search query is always lowercase
    
    if (!searchQuery) return searchList; // If search is empty, return the original list
  
    return searchList.filter(item => 
      Object.keys(item).some(key => 
        item[key]?.toString().toLowerCase().includes(searchQuery)
      )
    );
  }



  export const typesetPromiseFunction = () => {
    if (typeof window.MathJax !== 'undefined' && window.MathJax.typesetPromise && typeof window.MathJax.typesetPromise === 'function') {
        console.log('typesetPromise is defined and a function');
        console.log(window.MathJax);
        window.MathJax.typesetPromise();
    } else {
        console.log('MathJax or typesetPromise is not as expected');
        console.log('typeof window.MathJax:', typeof window.MathJax);
        console.log('window.MathJax.typesetPromise:', window.MathJax?.typesetPromise);
        console.log('typeof window.MathJax.typesetPromise:', typeof window.MathJax?.typesetPromise);
    }
  }