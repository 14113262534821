import styled from "styled-components";


export const VerifyMcqWrapper = styled.div`
margin:2% 2% 0;
.h1,h2,h3,h4,h5,h6,p{
    margin: 2px 0 !important;
}

.listParent{
   
    border: 1px solid #b3adad;
    padding :1%;
    margin-top: 1%;
    display:grid;
    grid-template-columns: 1fr;
    gap: 00px;
    justify-content: center;
    align-items: stretch
}
.card{
    padding: 1% 2%;
    // background: radial-gradient(circle, rgba(241,244,239,1) 0%, rgba(228,240,221,1) 35%, rgba(232,245,220,1) 100%);
    background: white;
    border-top: 1px solid #b3adad;
    border-left: 1px solid #b3adad;
    border-right: 1px solid #b3adad;
    border-radius:7px 7px 0px 0px;
    /* color: white; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 1% 0% 0%;
}
.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
}
.question{
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}
.question p{
    font-size: 14px;
}
.options{
    margin-top: 1%;
}
.conceptParent{
    margin-top: 1%;
    padding: 2%;
    border: 0.5px solid gray;
    border-radius: 5px;
    
}
.uploaderComment{
    padding: 1%;
    background: white;
    border: 0.5px solid gray;
    border-radius: 10px;
    color: red;
    margin-bottom:2%;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
}
.Commentlabel{
    font-size: 12px;
    color:black;
}

.topicName{
    font-size: 16px;
    color: black;
    font-weight: 500;
    margin-bottom: 1%;
}
.formElement{
    width: 100%;
}
.optionsDiv{

   
}
.options .option{
    margin-top: 1% !important;
    font-size: 12px;
}
.correctAns{
    margin-top: 1%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.explaination{
    margin-top: 2%;
    font-size: 14px;
    color: #074c24;
}

.feedbackCard{
    background: white;
    border-bottom: 1px solid #b3adad;
    border-left: 1px solid #b3adad;
    border-right: 1px solid #b3adad;
    padding: 1% 2%;
    border-radius: 0px 0px 7px 7px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

}
.feedbackParent{
    gap: 20px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: start;
}
.feedback{
    width: 100%;
}
.comment{
    width: 100%;
}
.submitButton{
    display: flex;
    justify-content: center;
    margin: 2%;
}
.submitButton button{
    height: 30px;
    border: none;
    background: #1677FF;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    padding: 0 3%;
}


`;