import React from 'react'
import { ContentCardWrapper } from './ContentCard.style'
import addIcon from "assets/images/addIcon.svg";
import removeIcon from "assets/images/removeIcon.svg";
import SecuredServices from 'api/securedServices';

export default function ContentCard({tag1,tag2,cardHeader,status,topic,concept,date,buttonType,handleButtonClick,onClickOnCard,onClickOnFeedback,isVerifier=false,auto_generated}) {
  
  return (
    <ContentCardWrapper>
          <div className={status=="reverted"?"revertedCard":'card'}>
                        {
                            tag1 &&  <div className={tag1.toLowerCase()=="mission"?'tag1-alt':'tag1'}><p>{tag1}</p></div>
                        }
                        {
                            tag2 &&  <div className={tag2=="program"?'tag2-alt':'tag2'}><p>{tag2}</p></div>
                        }
                
                        <div className='cardHeader'  onClick={onClickOnCard}>
                        <p className={status=="reverted"?"reverted":""}> {cardHeader} {auto_generated&& parseInt(auto_generated)!=0?`(${auto_generated})`:""}</p>
                        </div>
                        <div className='topicAndConcept'>
                                <p className={status=="reverted"?"reverted":""}>Topic :{topic}</p>
                                <p className={status=="reverted"?"reverted":""}>Concept: {concept}</p>
                        </div>
                        <div className='dateAndButton'>
                            <p className='date'>{date}</p>
                            {
                              isVerifier && status=="reverted"?"":
                              status=="reverted" ?<button className='feedback' onClick={onClickOnFeedback}>FeebBack !</button>:
                             buttonType===1?<img onClick={handleButtonClick} src={addIcon} width={20} alt="" />:
                             buttonType==2?<img onClick={handleButtonClick} src={removeIcon} width={20} alt="" />:""
                            }
                        </div>
                      
                      
                    </div>
    </ContentCardWrapper>
  )
}
