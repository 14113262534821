import React, { useEffect, useState,useRef } from 'react'
import { ManageConceptsWrapper } from './ManageConcepts.style'
import { Button, List,Select ,Modal, Popconfirm, Popover, message} from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SecuredServices from 'api/securedServices';
import {DragOutlined,ExclamationCircleFilled,CloseOutlined} from '@ant-design/icons';
import GenricModal from 'components/comman/modalComponents/modal/modal';

const { confirm } = Modal;

const initialItems = [
    { id: '1', content: 'Line 1' },
    { id: '2', content: 'Line 2' },
    { id: '3', content: 'Line 3' },
  ];

export default function ManageConcepts() {
    const [items, setItems] = useState([]);
    const [addNewConceptModal,setAddNewConceptModal] = useState(false);
    const [selectedData,setSelectedData] = useState({});
    const [optionsData,setOptionsData] = useState({
        grades:[
            6,7,8,9,10
        ],
        topics:[],
        stream:[
    ]

    });
    const [selectedgrades,setSelectedGrades] = useState(6);
    const [selectedtopics,setSelectedTopics] = useState(null);
    const [selectedStream,setSelectedStream]=useState();
    const [isSavedFlag,setIsSavedFlag] = useState(1);
    const [showPopover,setShowPopover] = useState(false);
    const conceptsListRef = useRef(null);



    const onDragEnd = (result) => {
      const { destination, source } = result;
      if (!destination) return;
  
      const reorderedItems = Array.from(items);
      const [movedItem] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, movedItem);
      setItems(reorderedItems);
    };

    useEffect(()=>{
        SecuredServices.getTopics(selectedgrades).then(res=>{
            console.log(res);
            setOptionsData({...optionsData,topics:res.data.data.data});
            setSelectedTopics(res.data.data.data[0].topic_id);
        })
    },[selectedgrades])

    useEffect(()=>{
        if(selectedtopics!=null){

            SecuredServices.getTopicStream(selectedtopics).then(res=>{
              if(res.data.status==1){
                setOptionsData({...optionsData,stream:res.data.data.data});
                setSelectedStream(res.data.data.data[0].stream_id);
              }
            })

           
        }
      
    },[selectedtopics])

    useEffect(()=>{
      if(selectedgrades!=null && selectedtopics!=null && selectedStream!=null){
        let selectedT = optionsData.topics.filter((value)=>value.topic_id===selectedtopics)[0]?.name
        SecuredServices.getTopicConcepts(selectedgrades,selectedT,selectedStream).then(res=>{
          console.log(res);
          if(res.data.status==1){
              res.data.data.data.concepts.forEach((value,index)=>{
                  value.id = index.toString();
              });
              setItems(res.data.data.data.concepts)
          }
          else{
            message.error(res.data.message);
            setItems([])
          }
         
      })
      }

    },[selectedgrades,selectedtopics,selectedStream])



    const handleSaveConcepts = ()=>{
     let concept_ids = items.map((value)=>value.concept_id);
     updateTopicConcepts(concept_ids);
    }




    


    const handleAddNewConceptModal = (data)=>{


        confirm({
            title: 'Are you Sure, you want to add this concept?',
            icon: <ExclamationCircleFilled />,
            content: <div>
                <p>Topic: {optionsData.topics.filter((value)=>value.topic_id===selectedtopics)[0]?.name}</p>
                <p>Concept: {data.concept_name}</p>
            </div>,
            async onOk() {
              //we need to call the update concepts api by adding the new concept in the last,
              //but also we need to add the concept in the top with red color on the top of list
              // so that it will be highlighted to the admin to make sure he adjusts it properly 
              // to it's correct place;
              try {
                if(data.isExiting==true){

                  let concept_Ids = items.map((value)=>value.concept_id);
                  if(concept_Ids.includes(data.concept_id)){
                    message.error("This Concept has already been Mapped with this Topic and Stream.");
                    return;
                  }
                  concept_Ids.push(data.concept_id);
                  await updateTopicConcepts(concept_Ids);
                  setItems([...items,{id:items.length.toString(),concept_id:data.concept_id,name:data.concept_name}]);
                  setAddNewConceptModal(false);
                  setIsSavedFlag(0);
                  setShowPopover(true);
                  scrollToLastItem();
                }
                else{
  
                  let data1= {
                    concept_name: data.concept_name
                  }
                  SecuredServices.createNewConcept(data1).then(async(res)=>{
                    if(res.data.status==1){
                      console.log(res);
                      message.success(res.data.message);
                        let concept_Ids = items.map((value)=>value.concept_id);
                        concept_Ids.push(res.data.data.data.concept_id);
                        await updateTopicConcepts(concept_Ids);
                         setItems([...items,{id:items.length.toString(),concept_id:res.data.data.data.concept_id,name:data.concept_name}]);
                        
                         setAddNewConceptModal(false);
                         setIsSavedFlag(0);
                         setShowPopover(true);
                         scrollToLastItem();
                    } 
                    else{
                      message.error(res.data.message);
                    }  
                  })
  
                }
              } catch (error) {
                console.log(error);
                message.error(error);
              }

            
             
             
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    
    }

    useEffect(()=>{
      console.log(items);
    },[items])


    const scrollToLastItem = () => {
      console.log(conceptsListRef,'conceptsListRef',conceptsListRef.current);
      
      if (conceptsListRef.current) {
        const lastElement = conceptsListRef.current.querySelector(
          '.ant-list-items:last-child'
        );
        console.log(lastElement);
        lastElement?.scrollIntoView({ behavior: 'smooth' });
      }
    };



    const updateTopicConcepts = (concept_ids)=>{
      let promise = new Promise((resolve, reject) => {
        let data = {
          topic_name: optionsData.topics.filter((value)=>value.topic_id===selectedtopics)[0]?.name,
          grade: selectedgrades,
          stream_id: selectedStream,
          concept_ids: concept_ids
        }
        console.log(data);
        SecuredServices.updateTopicConcepts(data).then((res)=>{
          if(res.data.status==1){
            message.success(res.data.message);
            setIsSavedFlag(1);
            setShowPopover(false);
            resolve(data);
          }
          else{
            reject(res.data.message);
          }
        }).catch((err)=>{
          reject(err.message);
        })
      })
     
      return promise;
    }

    const handleRemoveConcept = (item)=>{
      console.log(item);
      confirm({
        title: 'Are you Sure,You want to remove this concept?',
        icon: <ExclamationCircleFilled />,
        content: <div>
          <p>Concept Id: {item.concept_id}</p>
          <p>Concept Name : {item.name}</p>

          <strong>Note: It will be removed for currently choosen Stream only.</strong>
        </div>,
        onOk() {
          let concept_ids = items.filter((value)=>value.concept_id!=item.concept_id).map((value)=>value.concept_id);
          console.log(concept_ids);
          updateTopicConcepts(concept_ids);
          setItems(items.filter((value)=>value.concept_id!=item.concept_id));
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }

  return (
    <ManageConceptsWrapper>
    

<div className=''>
            <h4 className=''>Manage Concepts</h4>
        </div>
    <div className='formElement'>
    <div className="formElementGrade">
                <label className='label'>Grade:</label>

                <Select 
                value={selectedgrades}
                style={{width:"100%"}}
                onChange={(value)=>{
                    setSelectedGrades(value)
                }}
                >
                  {
                    optionsData.grades.map((value,index)=>{
                      return <Select.Option key={index} value={value}>{value}</Select.Option>
                    })
                  }
                    {/* <Select.Option value={6}>6</Select.Option>
                    <Select.Option value={7}>7</Select.Option>
                    <Select.Option value={8}>8</Select.Option>
                    <Select.Option value={9}>9</Select.Option> */}
                </Select>
        </div>

        <div className="formElementTopic">
                <label className='label'>Topic:</label>
                <Select 
                value={selectedtopics}
                style={{width:"100%"}}
                onChange={(value)=>{
                 console.log(value);
                 setSelectedTopics(value)
                }}
                >
                  {/* <Select.Option value={-1}>ALL</Select.Option> */}
                  {
                    optionsData.topics.map((value,index)=>{
                      return <Select.Option key={index} value={value.topic_id}>{value.name}</Select.Option>
                    })
                  }
                    {/* <Select.Option value={1}>Admin</Select.Option>
                    <Select.Option value={2}>Uploader</Select.Option>
                    <Select.Option value={3}>Programer</Select.Option>
                    <Select.Option value={4}>Verifier</Select.Option> */}
                </Select>
            </div>

            <div className="formElementStream">
                <label className='label'>Stream:</label>
                <Select 
                value={selectedStream}
                style={{width:"100%"}}
                onChange={(value)=>{
                 console.log(value);
                 setSelectedStream(value)
                }}
                >
                  {/* <Select.Option value={-1}>ALL</Select.Option> */}
                  {
                    optionsData.stream.map((value,index)=>{
                      return <Select.Option key={index} value={value.stream_id}>{value.name}</Select.Option>
                    })
                  }
                    {/* <Select.Option value={1}>Admin</Select.Option>
                    <Select.Option value={2}>Uploader</Select.Option>
                    <Select.Option value={3}>Programer</Select.Option>
                    <Select.Option value={4}>Verifier</Select.Option> */}
                </Select>
            </div>
    </div>
      

        <div className='manageConceptsParent'>
            <div className='labelCon'>
            <p>Concepts (drag and drop the concepts in their correct order):</p>
                <Button type='primary' onClick={
                  ()=>{
                    let data ={
                    }
                    data.topicName = optionsData.topics.filter((value)=>value.topic_id===selectedtopics)[0]?.name;
                    data.grade = selectedgrades;
                    data.stream = optionsData.stream.filter(value=>value.stream_id===selectedStream)[0]?.name;
                    setSelectedData(data);
                    setAddNewConceptModal(true)
                  }
                }>Add New Concept</Button>
            </div>
        <DragDropContext onDragEnd={onDragEnd} >
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef} // Make sure ref is passed here// Basic styling for layout
            style={{maxHeight:"44vh",overflow:"auto"}}
          >
            <List
              bordered
              size="small"
              dataSource={items}
              renderItem={(item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} ref = {conceptsListRef}>
                  {(provided) => {
                    if(isSavedFlag==false && parseInt(item.id) === items.reduce((max, obj) => (parseInt(obj.id) > max ? parseInt(obj.id) : max), 0)){
                      return <Popconfirm
                      color={""}
                      title="Changes Not Saved"
                      description="Please Rearrange this concept in correct order, otherwise it will be added in the last place of the concepts."
                      okType='primary'
                      cancelText=""
                      open={showPopover}
                      style={{color:"white"}}
                      onConfirm={()=>{setShowPopover(false)}}
                     >
                        <div
                      className='greenConcept conceptList'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                  >
                 
                        <DragOutlined />
                       <span>{item.concept_id}</span>
                       <p>{item.name}</p>
                       <div className='closeIcon' onClick={()=>{handleRemoveConcept(item)}}> <CloseOutlined /></div>
                   
                    

   
                  </div> 
                  </Popconfirm>
                    }
                    else{
                     return <div
                      className='conceptList'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                    }}
                  >
                 
                        <DragOutlined />
                       <span>{item.concept_id}</span>
                       <p>{item.name}</p>
                       <div className='closeIcon' onClick={()=>{handleRemoveConcept(item)}}> <CloseOutlined /></div>
                      
                    

                     
                   
                     
                  </div>  
                    }
                  }}
                </Draggable>
              )}
            />
            {provided.placeholder} {/* Placeholder helps maintain space during dragging */}
          </div>
        )}
      </Droppable>
    </DragDropContext>

        <div className='saveConcepts'><Button onClick={handleSaveConcepts}>Save </Button></div>
        </div>



        <GenricModal
        closable={true}
        data={selectedData}
        onSubmitClick={handleAddNewConceptModal}
        width={500}
        title={"Add New Concept"}
        isOpen={addNewConceptModal}
        toggle={()=>{
            setAddNewConceptModal(false);
        }}
        />
      
    </ManageConceptsWrapper>
  )
}
