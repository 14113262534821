import React, { useEffect, useState } from 'react'
import { VerifyMcqWrapper } from './VerifyMcq.style'
import { Breadcrumb, Radio, Input, Alert, notification,Select,message } from 'antd'
import SecuredServices from 'api/securedServices'
import { typesetPromiseFunction } from 'helper/utils';

export default function VerifyMcq() {
    const params = new URLSearchParams(location.search);
    const qId = params.get('q_id');
    const q_name = params.get('q_name');
    const [verifData, setVerifData] = useState([]);
    const [concept,setConcept] = useState({});
    const [conceptsList,setConceptsList] = useState([]);

    const [data, setData] = useState([]
    )

    const [mathjaxConfig, setMathjaxConfig] = useState({
        tex: { packages: { "[+]": ["input"] },
        inlineMath: [["$", "$"], ["\\(", "\\)"]],
        displayMath: [["$$", "$$"], ["\\[", "\\]"]],  
     },
     
        startup: {
            ready() {
                const Configuration =
                    window.MathJax._.input.tex.Configuration.Configuration;
                const CommandMap = window.MathJax._.input.tex.SymbolMap.CommandMap;
                const TEXCLASS = window.MathJax._.core.MmlTree.MmlNode.TEXCLASS;

                new CommandMap(
                    "input",
                    { input: "Input" },
                    {
                        Input(parser, name) {
                            const xml = parser.create("node", "XML");
                            const id = parser.GetBrackets(name, "");
                            const w = parser.GetBrackets(name, "3em");
                            const value = parser.GetArgument(name);
                            xml.setXML(
                                window.MathJax.startup.adaptor.node("input", {
                                    id: id,
                                    value: value,
                                    style: { width: w },
                                    xmlns: "http://www.w3.org/1999/xhtml",
                                }),
                                window.MathJax.startup.adaptor
                            );
                            xml.getSerializedXML = function () {
                                return this.adaptor.outerHTML(this.xml) + "</input>";
                            };
                            parser.Push(
                                parser.create(
                                    "node",
                                    "TeXAtom",
                                    [
                                        parser.create("node", "semantics", [
                                            parser.create("node", "annotation-xml", [xml], {
                                                encoding: "application/xhtml+xml",
                                            }),
                                        ]),
                                    ],
                                    { texClass: TEXCLASS.ORD }
                                )
                            );
                        },
                    }
                );
                Configuration.create("input", { handler: { macro: ["input"] } });
                window.MathJax.startup.defaultReady();
            },
        },
    })
    useEffect(() => {
        // setIpData({"Qtext": "\n \nSuppose \\(2 x - 5 y + 7 = 0\\), and \\(px + 6 y + 7 = 0\\) are parallel lines. Then find the value of \\(p\\).\n\n\n", "Steps": [{"id": "s1", "n_params": 2, "step_txt": "\n $$\\text{ The given equations of line are, }$$\n \\[(2) x - (5 ) y + (7 ) = 0\\]\n \\[y = \\frac{\\input[s1v1]{}}{5} x + \\frac{7}{5}\\]\n \\[y= mx +c \\]\n \\[ m = \\frac{2}{\\input[s1v2]{}}\\]\n \n "}, {"id": "s2", "n_params": 2, "step_txt": "\n $$\\text{ The given equations of line are, }$$\n \\[px + (6 ) y + (7 ) = 0\\]\n \\[y = \\frac{-p}{\\input[s2v1]{}} x - \\frac{7}{6}\\]\n \\[y= mx +c \\]\n \\[ m = \\frac{-p}{\\input[s2v2]{}}\\]\n \n "}, {"id": "s3", "n_params": 2, "step_txt": "\n \\[ m = \\frac{2}{5}\\]\n \\[ m = \\frac{-p}{6}\\]\n $$\\text{ line are parallel, so we can write , p we get}$$\n \\[\\frac{2}{5} = \\frac{-p}{6}\\]\n \\[p = \\frac{ -\\input[s3v1]{}}{\\input[s3v2]{}}\\]\n \\[p = \\frac{-12}{5}\\]\n \n "}]});
        //code for Mathjax to initialise
        window.MathJax = mathjaxConfig;
        // window.MathJax.Hub.Config({
        //     "HTML-CSS": { linebreaks: { automatic: true } },
        //     SVG: { linebreaks: { automatic: true } }
        //   });
        let script = document.createElement("script");
        script.defer = true;
        script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml.js";
        document.head.appendChild(script);

    }, [])

    useEffect(() => {
        
        SecuredServices.getVerificationDetails(false,qId).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                if(res.data.data.length>0){
                   let json =  JSON.parse(res.data.data[0].mcq_json);
                   console.log(json);
                   setData(json);
                   let feedBackD={};
                    for(let i=0;i<json.length;i++){
                        feedBackD[`isAccept${i+1}`]=false;
                        feedBackD[`comment${i+1}`]="";
                    }
                   setFeedback(feedBackD);
                }

                setVerifData(res.data.data);
            }
        })
    }, [qId])

    useEffect(()=>{
        if(verifData.length>0){
            
            SecuredServices.getConcepts(verifData[0].topic_id).then((res)=>{
                if(res.data.status==1){
                    console.log(res.data.data);
                    setConceptsList(res.data.data.data);
                }
            })
        }
    },[verifData])

    useEffect(() => {
        typesetPromiseFunction();

    }, [data])
    const [feedBack, setFeedback] = useState({
    })

    const HandleFeedbackChange = (e, index) => {
        console.log(e.target.value,index);
        setFeedback({
            ...feedBack,
            [`isAccept${index + 1}`]: e.target.value,
        });
    }

    const handleSubmitVerification  = ()=>{
        let data = feedBack;
        console.log(data);
        let submitFlag = 1;
        let isAccept =1;
        Object.keys(data).forEach((item,index)=>{
            if(item.includes("isAccept") && data[item] == false ){
                isAccept=0;
                if(data[`comment${index+1}`]==""){
                    submitFlag = 0;
                    notification.error({
                        message: "Error",
                        description: "Please Enter the comment if you reject any question.",
                        placement: "bottomRight",
                    })
                    return;
                }  
            }
        })
        if(!concept?.concept_id){
            submitFlag = 0;
            notification.error({
                message: "Error",
                description: "Please Select the concept.",
                placement: "bottomRight",
            })
        }
        if(submitFlag ==1){
            //call the api to submit the mcq verification
            // pass the comment isAccept etc
            let submitD = {
                concept:concept,
                comment: JSON.stringify(data),
                isAccept: isAccept,
                q_id:qId,
                q_name:q_name,
                type:"mcq"
            }

            SecuredServices.submitVerificationMcq(submitD).then((res)=>{
                console.log(res.data);
                if(res.data.status==1){
                    message.success(res.data.message);
                }
                else{
                    message.error(res.data.message);
                }
            })
            console.log(submitD);
        }
    }
    const HandleCommentChange = (e, index) => {
        console.log(e.target.value);
        let feedb = {...feedBack};
        feedb[`comment${index + 1}`] = e.target.value;
        setFeedback(feedb);
        console.log(feedb);
        // setFeedback({
        //     ...feedBack,
        //     [`comment${index + 1}`]: e.target.value,
        // });
    }
    return (
        <VerifyMcqWrapper>
            <Breadcrumb separator=">">
                <Breadcrumb.Item>Verify Content</Breadcrumb.Item>
                <Breadcrumb.Item>{q_name}</Breadcrumb.Item>
            </Breadcrumb>
            <div className='conceptParent'>
                <div className='uploaderComment'>
                    <span className='Commentlabel'>Uploader Comment:</span>
                    <p>{verifData[0]?.uploader_comment}</p>
                </div>
                <div className='topicName'>
                    <p>Topic Name : {verifData[0]?.topic_name}</p>
                </div>
            <div className="formElement">
                <label className='label'>*Select Concept:</label>
                <Select 
                placeholder="Select Concept"
                value={concept.concept_id}
                style={{width:"100%"}}
                onChange={(value)=>setConcept(conceptsList.find(item=>item.concept_id==value))}
                >
                      {
                       conceptsList.map((concept)=>{
                           return <Select.Option key={concept.concept_id} value={concept.concept_id}>{concept.name}</Select.Option>
                       })
                   }
                </Select>
            </div>
            </div>
            <div className="outerDiv">
                <div className='listParent'>
                    {
                        data?.map((item, index) => {
                            return (
                                <div className="outerCard">
                                    <div className='card'>
                                        <div className='heading'>
                                            <p>Q_name : {verifData[0]?.q_name}</p>
                                            <p>Question Number : {index + 1}</p>
                                        </div>
                                        <div className='question'>
                                            <h5>Question :</h5>
                                            <p>{item?.question}</p>
                                        </div>
                                        <div className='options'>
                                            <h5>Options :</h5>
                                            <div className='optionsDiv'>
                                                <p className='option'>A: {item?.optionA}</p>
                                                <p className='option'>B: {item?.optionB}</p>
                                                <p className='option'>C: {item?.optionC}</p>
                                                <p className='option'>D: {item?.optionD}</p>
                                            </div>
                                        </div>
                                        <div className='correctAns'>
                                            <h5>Correct Answer :</h5>
                                            <p>{item?.correctOption?.toUpperCase()} ({item[`option${item?.correctOption}`]})</p>
                                        </div>
                                        <div className="explaination">
                                            <p>Explanation : {item?.explanation}</p>
                                        </div>
                                    </div>
                                    <div className="feedbackCard">

                                        <div className='feedbackParent'>
                                            <div className="feedback">
                                                <h5>Feedback:</h5>
                                                <Radio.Group onChange={(e) => HandleFeedbackChange(e, index)} value={feedBack[`isAccept${index + 1}`]}>
                                                    <Radio value={true} style={{color:"green"}}>Accept</Radio>
                                                    <Radio value={false} style={{color:"red"}}>Reject</Radio>
                                                </Radio.Group>
                                            </div>
                                            {
                                                !feedBack[`isAccept${index + 1}`] &&  <div className='comment'>
                                                <h5>Comment:</h5>
                                                <Input.TextArea placeholder="Enter Your Comment" rows={2} value={feedBack[`comment${index + 1}`]} onChange={(e) => HandleCommentChange(e, index)} />
                                            </div>
                                            }
                                          
                                        </div>



                                    </div>
                                </div>

                            )
                        })
                    }

                </div>
            </div>
            <div className="submitButton">
                <button onClick={handleSubmitVerification}>Submit Verification</button>
            </div>
        </VerifyMcqWrapper>
    )
}
