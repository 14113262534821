import styled from "styled-components";


export const ChangePasswordWrapper = styled.div`
.formElement{
    margin: 4% 0%;
}
.changePasswordForm{
    margin-top:7%;
}

.button{
    margin-top: 6%;
    width: 100%;
    text-align: center;
}
.submitButton{
    border: none;
    padding: 4px 45px;
    height: 30px;
    background: #007aff;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

`;