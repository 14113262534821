import styled from "styled-components";


export const ShowFeebackModalWrapper = styled.div`
.veriferComments{
    width: 95%;
    min-height: 200px;
    overflow-y: scroll;
    overflow-x:scroll;
    border: 1px solid #d5d5d5;
    background:#f9f9f9;
    border-radius: 10px;
    padding: 2%;
    margin-top: 2%;
}
`;