import styled from "styled-components";

export const ShowTextWrapper = styled.div`
.showText{
    border: 1px solid gray;
    padding: 0% 2%;
    border-radius: 9px;
    background: #efefef;
}
.title{
    margin-top: 6%;
    display: block;
    font-weight: bold;
    font-size: 14px;
}
`;