import React, { useState,useEffect } from 'react'
import { SubmitMcqProgramWrapper, SubmitMissionProgramWrapper } from './SubmitMcqInstance.style';
import { Breadcrumb, Input,Modal,message } from 'antd';
import { useLocation, } from 'react-router-dom';
import Spinner from "assets/images/Spinner.gif";
import SecuredServices from 'api/securedServices';
import GenricModal from 'components/comman/modalComponents/modal/modal';

export default function SubmitMcqProgram() {


  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isRevert = params.get('is_revert');
  const qName = params.get('q_name');
  const topicId = params.get('topic_id');
  const conceptId = params.get('concept_id');
  const [userInputData, setUserInputData] = useState({
    mcq_json_1: null,
    mcq_json_2: null,
    mcq_json_3: null,
    mcq_json_4: null,
    mcq_json_5: null,
  })
  const [missionPdfModal, setMissionPdfModal] = useState(false);
  const [blockSubmitAndTest, setBlockSubmitAndTest] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);


  const handleSubmit  = ()=>{
    // if(isSubmit==false){
    //   setBlockSubmitAndTest(true);
    // }
    console.log(userInputData);
    setBlockSubmitAndTest(true);
    setDisableSubmit(true);
   
    let data = userInputData;
    let flag =1;
    if(data.program==null || data.mcqJson==null){
        message.error("Please fill all the required(*) fields");
        setDisableSubmit(false);
        setBlockSubmitAndTest(false);
        flag=0;
    }

    // console.log(validateJson(JSON.stringify(data.rubrics)));

    if(validateJson(data.mcqJson)==false){
        message.error("Please enter valid rubrics");
        setDisableSubmit(false);
        setBlockSubmitAndTest(false);
        flag=0;
    }

    if(flag ==1){
      console.log(data);
      let submitdata = {
        topic_id: topicId,
        concept_id:conceptId,
        q_name:qName,
        program:data.program,
        mcq_json:JSON.stringify(JSON.parse(data.mcqJson)),
      }
      console.log(submitdata);
    //   SecuredServices.submitMissionProgramAndRubrics(submitdata).then((res)=>{
    //     console.log(res);
    //     if(res.data.status ==1){
    //       message.success(res.data.message);
    //       setDisableSubmit(false);
    //       setBlockSubmitAndTest(false);
    //       setMissionPdfModal(true);
    //         window.history.back();
    //     }
    //     else{
    //       Modal.error({
    //         title: 'Error',
    //         content: `${res.data.message} \n ${res.data.error}`
    //       })
    //       // message.error(res.data.message);
    //       setDisableSubmit(false);
    //       setBlockSubmitAndTest(false);
    //     }
    //   })
    //   .catch((err)=>{
    //     console.log(err);
    //     setDisableSubmit(false);
    //     setBlockSubmitAndTest(false);
    //   }).finally(()=>{
    //     setDisableSubmit(false);
    //     setBlockSubmitAndTest(false);
    //   })
    }
  }

  useEffect(()=>{
    console.log(isRevert);
    if(isRevert=="true" || isRevert==true){
      console.log(isRevert);
        // SecuredServices.getProgramDetailsByQName(qName).then((res)=>{
        //     console.log(res.data.data);
        //     if(res.data.status==1){
        //         setUserInputData({
        //             program:res.data.data[0].program,
        //             rubrics:JSON.parse(res.data.data[0].rubrics)
        //         })
        //         // setUserInputData(res.data.data[0]);
        //     }
        // })
    }
},[isRevert])

  const validateJson = (data)=>{
    try{
        console.log(data);
        JSON.parse(data);
        console.log(data);
        return true;
    }
    catch(err){
      console.log(err)
        return false;
    }
  }
  return (
    <SubmitMcqProgramWrapper>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item onClick={() => { window.history.back() }}>Create Content</Breadcrumb.Item>
        <Breadcrumb.Item>{qName}</Breadcrumb.Item>
      </Breadcrumb>

      <h3>Submit Mcq Program</h3>

      <div className='formParent'>
        <div>
          <h2>Submit Mcq Program and JSON</h2>
        </div>
        <div className='form'>
          <div className='formElement'>
            <label htmlFor="" className='label'>Mcq JSON 1:</label>
            <Input.TextArea rows={2}
              placeholder='Input Mcq Json for Instance 1'
              value={userInputData.program}
              name=""
              onChange={(e) => setUserInputData({ ...userInputData, mcq_json_1: e.target.value })}
            />
          </div>
          <div className='formElement'>
            <label htmlFor="" className='label'>Mcq JSON 2:</label>
            <Input.TextArea rows={2}
              placeholder='Input Mcq Json for Instance 2'
              value={userInputData.program}
              name=""
              onChange={(e) => setUserInputData({ ...userInputData, mcq_json_2: e.target.value })}
            />
          </div>
          <div className='formElement'>
            <label htmlFor="" className='label'>Mcq JSON 3:</label>
            <Input.TextArea rows={2}
              placeholder='Input Mcq Json for Instance 3'
              value={userInputData.program}
              name=""
              onChange={(e) => setUserInputData({ ...userInputData, mcq_json_3: e.target.value })}
            />
          </div>
          <div className='formElement'>
            <label htmlFor="" className='label'>Mcq JSON 4:</label>
            <Input.TextArea rows={2}
              placeholder='Input Mcq Json for Instance 4'
              value={userInputData.program}
              name=""
              onChange={(e) => setUserInputData({ ...userInputData, mcq_json_4: e.target.value })}
            />
          </div>
          <div className='formElement'>
            <label htmlFor="" className='label'>Mcq JSON 5:</label>
            <Input.TextArea rows={2}
              placeholder='Input Mcq Json for Instance 5'
              value={userInputData.program}
              name=""
              onChange={(e) => setUserInputData({ ...userInputData, mcq_json_5: e.target.value })}
            />
          </div>

         


          <div className={blockSubmitAndTest?"saveAndTestButton block":'saveAndTestButton '} onClick={()=>handleSubmit(false)}>
                <button>{blockSubmitAndTest? <div><img src={Spinner} width={20}/> processing</div>: "Save and Test"} </button>
            </div>


            <div className='buttonsSections'>
            <div className='submitButton' onClick={()=>handleSubmit(true)} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
             {blockSubmitAndTest?<div style={{display:"flex",alignItems:"center",gap:"5px"}}><img src={Spinner} width={20}/>processing</div>: "Submit"}
                </div>
            </div>

        </div>
      </div>

      <GenricModal
            closable={true}
            width={700}
            title={"Verify Mission Program"}
            isOpen={missionPdfModal}
            toggle={()=>setMissionPdfModal(false) }
        />

    </SubmitMcqProgramWrapper>
  )
}
