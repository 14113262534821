import React, { useEffect } from 'react'
import { ShowFeebackModalWrapper } from './ShowFeebackModal.style'
import JsonFormatter from 'react-json-formatter'
import { IqLink } from 'helper/constants'

export default function ShowFeedbackModal(data) {

    const jsonStyle = {
        propertyStyle: { color: 'red' },
        stringStyle: { color: 'green' },
        numberStyle: { color: 'darkorange' }
      }

      useEffect(()=>{
        console.log(data.data);
        console.log(data.data.type=="program" ?data.data.pdf_link:"");
      },[data])

  return (

    <ShowFeebackModalWrapper>
        <h5>Verifier Comments</h5>
        <a href={data.data.type=="program"?data.data.verification_link:`${IqLink}ip-verify/${data.data.token}/${data.data.topic_id}/${data.data.q_id}`} target="_blank">Verification Link</a>
        <div className='veriferComments'>   
        <JsonFormatter json={data.data.comment} tabWith={4} jsonStyle={jsonStyle} />

        </div>
    </ShowFeebackModalWrapper>
  )
}
