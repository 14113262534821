import styled from "styled-components";

export const ManageContentWrapper = styled.div`

.filters{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;
    margin:0% 0% 3%;

}
.buttonsSections{
    display: flex;
    gap: 20px;
    justify-content: center;
}
.buttonClass{
    border: none;
    /* background: #0061ff; */
    color: white;
    padding: 1% 3%;
    border-radius: 5px;
}
.commit{
    background: #0061ff;
}
.rtp{
    background:#ffa800;
}   
.rtv{
    background:#ffce00;
}
.discard{
    background: red;
}
.showDetailsBtn{
    background: none;
    border: none;
    color: #0076ff;
    text-decoration: underline;
    font-weight: bold;
    width: 100px;
    cursor: pointer;
}
.totalRowsSelected{
    margin:1% 0% 4%;
}
`;