import styled from "styled-components";

export const ManageUsersWrapper = styled.div`
.createNewUser{
    padding: 0% 2%;
    height: 30px;
    font-size: 12px;
    border-radius: 5px;
    background: #117aff;
    width: 120px;
    gap: 5px;
    text-align: center;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
}

.deleteUser{
    border: navajowhite;
    background: red;
    color: #fff6f6;
    border-radius: 5px;
    padding: 4px 13px;
    font-size: 10px;
}
.buttonsDiv{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

}
`;