import styled from "styled-components";


export const ReactCropImageWrapper = styled.div`
.mainCropImageDiv{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cropImage{

}

.buttonsSections{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
    justify-content: center;
}
.cameraButton{
    border: none;
    background: #009bff;
    color: white;
    padding: 2%;
    border-radius: 5px;
    width: 25%;
}
.gallaryButton{
    border: none;
    background: #54bc54;
    color: white;
    padding: 2%;
    width: 25%;
    border-radius: 5px;
    cursor: pointer;
}
`;