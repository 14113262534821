import React,{useState,useEffect} from 'react';
import { VerifyContentWrapper } from './VerifyContent.style';
import { Table, Tabs, message } from 'antd';
import ContentCard from 'components/comman/contentCard/ContentCard';
import SecuredServices from 'api/securedServices';
import { getDate } from 'helper/utils';
import { useNavigate } from 'react-router-dom';
import { RouteDefinitions } from 'api/routes/routeDefinitions';
import SearchBar from 'components/comman/SearchBar/SearchBar';

export default function VerifyContent() {
    const Navigate = useNavigate();
    const [allPendingVerifications, setAllPendingVerifications] = useState([]);
    const [baseAllPendingVerifications, setBaseAllPendingVerifications] = useState([]);
    const [allUserVerifications, setAllUserVerifications] = useState([]);
    const [baseAllUserVerifications, setBaseAllUserVerifications] = useState([]);
    const [activeKey,setActiveKey] = useState("1");
    const [selectedCard,setSelectedCard] = useState({});
    const [questionDetailsModal,setQuestionDetailsModal] = useState(false);
    const [submittedVerifications,setSubmittedVerifications] = useState([]);
    const [cmsAdminToken,setCmsAdminToken] = useState("");


    useEffect(()=>{
        if(activeKey=="1"){
            getAllPendingVerifications();
        }
        else if(activeKey=="2"){
            getUserPendingVerifications();
        }
        else{
            //get all the submitted verifcations with all the details
            getUserSubmittedVerifications();

        }
            
    },[activeKey])

    const getAllPendingVerifications = ()=>{
        SecuredServices.getAllPendingVerification().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                setAllPendingVerifications(res.data.data);
                setBaseAllPendingVerifications(res.data.data);
            }
        })
    }

    const getUserPendingVerifications = ()=>{
        SecuredServices.getAllUserPendingVerification().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                setAllUserVerifications(res.data.data);
                setBaseAllUserVerifications(res.data.data);
                setCmsAdminToken(res.data.data[0]?.cms_admin_token);
            }
        })
    }


    const getUserSubmittedVerifications = ()=>{
        SecuredServices.getAllSubmittedVerification().then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                setSubmittedVerifications(res.data.data);
            }
            else{
                message.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
            message.error(err.message);
        })

    }

    const assignVerificationToUser = (index)=>{
        console.log(index);
        let data = {
            q_id:allPendingVerifications[index].q_id
        }
        SecuredServices.assignVerificationToUser(data).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                message.success(res.data.message);
                getAllPendingVerifications();
            }
            else{
                message.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
            message.error(err.message);
        })
    }

    const deAssignProgramToUser =(index)=>{
        console.log(index);
        let data = {
            q_id:allUserVerifications[index].q_id
        }
        SecuredServices.deAssignVerificationFromUser(data).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                console.log(res.data.data);
                message.success(res.data.message);
                getUserPendingVerifications();
            }
            else{
                message.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
            message.error(err.message);
        })
    }

    const columns = [
        {
          title: 'Question Name',
          key: 'q_name',
          render: (text, record) => {
            return (
              <span>{record.q_name} {record.auto_generated && parseInt(record.auto_generated)!=0?`(${record.auto_generated})`:""}</span>
            )}
        },
        {
          title: 'Topic Name',
          dataIndex: 'topic_name',
          key: 'topic_name',
        },
        {
          title: 'Concept Name',
          dataIndex: 'concept_name',
          key: 'concept_name',
        },
        {
          title: 'Type',
          dataIndex: 'content_type',
          key: 'content_type',
        },
        {
            title:"Q type",
            dataIndex:"question_type",
            key:"question_type"
        },
        {
          title: 'Grade',
          dataIndex: 'grade_id',
          key: 'grade_id',
        },
        {
            title:"Comment",
            dataIndex:"comment",
            key:"comment"
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
        },
      ];

    const handleClickOnCard = (item)=>{
            console.log(cmsAdminToken,item);
            
            if(item.question_type == "Iq"){
                Navigate(`${RouteDefinitions.ROUTE_SUBMIT_VERIFICATION}?token=${cmsAdminToken}&q_name=${item.q_name}&q_id=${item.q_id}&topic_name=${item.topic_name}&concept_name=${item.concept_name}&type=${item.question_type}&topic_id=${item.topic_id}&concept_id=${item.concept_id}`);
            }
            else if(item.question_type=="Mcq"){
                Navigate(RouteDefinitions.ROUTE_VERIFY_MCQ);
            }
            else{
                Navigate(`${RouteDefinitions.ROUTE_SUBMIT_MISSION_VERIFICATION}?qid=${item.q_id}&concept_name=${item.concept_name}&concept_id=${item.concept_id}&qname=${item.q_name}`);
            }
      
    }

    const handleSearchBarChange = (filteredData)=>{
        setAllPendingVerifications(filteredData);
    }

    const handleSearchBarChangeForUser = (filteredData)=>{
        setAllUserVerifications(filteredData);
    }
  return (
    <VerifyContentWrapper>
         <h3>Verify Content</h3>

        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={(key)=>setActiveKey(key)}>
            <Tabs.TabPane tab="Pending" key="1">
                <div className='verifyContentListParent'>
                <SearchBar data={baseAllPendingVerifications} setFilteredData={handleSearchBarChange} />

                <div className='verifyContentList'>

                    {allPendingVerifications.map((data,index)=>{
                        return <ContentCard
                        cardHeader={data.q_name} 
                        tag1={data.question_type}
                        auto_generated={data.auto_generated}
                        tag2={data.question_type=="Iq"?"exact":"program"}
                        topic={data.topic_name}
                        concept={data.concept_name}
                        date={getDate(data.updatedAt)}
                        buttonType={1}
                        handleButtonClick={ ()=>assignVerificationToUser(index) }
                        />
                    })}
                 
{/*                
                 <ContentCard 
                cardHeader={"playNumbers1-natural-Q1"} 
                tag2={"MCQ"}
                tag1={"rubrics"}
                topic={"Natural Numbers"}
                concept={"Number with no zero"}
                date={"10/10/2022"}
                buttonType={1}
                /> */}
                </div>
                </div>
              
            </Tabs.TabPane>
            <Tabs.TabPane tab="Assigned" key="2">
                <div className='verifyContentListParent'>
                <SearchBar data={baseAllUserVerifications} setFilteredData={handleSearchBarChangeForUser} />

                    <div className='verifyContentList'>

                        {
                            
                            allUserVerifications.map((item,index)=>{
                                console.log(item);
                                return <ContentCard 
                                key={index}
                                onClickOnCard = {()=>{
                                    
                                    setSelectedCard(item);
                                    handleClickOnCard(item)}}
                                cardHeader={item.q_name} 
                                auto_generated={item.auto_generated}
                                status = {item.status}
                                tag1={item.question_type}
                                tag2={item.question_type=="Iq"?"exact":"program"}
                               
                                topic={item.topic_name}
                                concept={item.concept_name}
                                date={getDate(item.updatedAt)}
                                isVerifier = {true}
                                buttonType={2}
                                handleButtonClick={ ()=>deAssignProgramToUser(index) }
                                />
                            })
                        }
                    </div>
                </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Submitted" key="3">
                    <Table columns={columns} dataSource={submittedVerifications} />
            </Tabs.TabPane>
            </Tabs>

    </VerifyContentWrapper>
  )
}
