import React, { useEffect, useState } from 'react';
import { Document, Page , pdfjs} from 'react-pdf';
import { ShowMissionPdfWrapper } from './ShowMissionPdf.style';
import tempPdf from "assets/images/temp.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
  ).toString();





export default function ShowMissionPdf() {

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }){
      setNumPages(numPages);
    }
  

    return (
      <ShowMissionPdfWrapper>
        <Document file={tempPdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <div>
            <button style={{display:pageNumber===1?"none":"block"}} onClick={() => setPageNumber(pageNumber - 1)}>prev</button>
            <button style={{display:numPages===pageNumber?"none":"block"}} onClick={() => setPageNumber(pageNumber + 1)}>next</button>
        </div>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </ShowMissionPdfWrapper>
    );
}
