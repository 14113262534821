import React, { useState } from 'react';
import LoginScreenWrapper from '../loginScreen.styled';
import LoginImage from "../../assets/images/login.svg";
import { Button, Input,message } from 'antd';
import AuthServices from '../../api/authServices';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/auth/authSlice';
import { useNavigate } from 'react-router-dom';


export default function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginData,setLoginData] = useState({
    username:"",
    password:""
  });

  const handleLoginClick = async()=>{
    console.log(loginData)
    if(!loginData.username || loginData.username===""){
      message.error("please enter username");
      return;
    }
    else if(!loginData.password || loginData.password===""){
      message.error("please enter password");
      return;
    }
    try {
     let res =  await AuthServices.POSTLogin(loginData);
     console.log(res);
      if(res.data.status===0){
        message.error(res.data.message);
      }
      else{
        console.log(res.data.data.data);
          dispatch(login(res.data.data.data));
          message.success(res.data.message);
          if(res.data.data.data.roles.find(role=>role.roleid===2)){
            navigate("/content/upload")
          }
          else if(res.data.data.data.roles.find(role=>role.roleid===3)){
            navigate("/content/create")
          }
          else if(res.data.data.data.roles.find(role=>role.roleid===4)){
            navigate("/content/verify");
          }
          else if(res.data.data.data.roles.find(role=>role.roleid===1)){
            navigate("/content/admin");
          }
      }
    } catch (error) {
        console.log(error);
    }
     
  }

  const handleKeyClick = (e)=>{
    if(e.key==="Enter"){
      handleLoginClick();
    }
  }

  return (
    <LoginScreenWrapper>
      <div className='main'>
        <div className='left'>
          <img src={LoginImage} alt="" />
        </div>
        <div className='right'>
          <div className='loginDiv'>
              <div className='loginHeader'>
                <h3>Login to your account</h3>
              </div>
              <label className='label' htmlFor="username">Username</label>
              <Input className='usernameinput' value={loginData.username} placeholder="enter username" size='large' onChange={e=>setLoginData({...loginData,username:e.target.value})} />
              <label className='label' htmlFor="Password">Password</label>
              <Input.Password className='passwordinput' value={loginData.password} placeholder="enter password" size='large' onChange={e=>setLoginData({...loginData,password:e.target.value})} onKeyDownCapture={handleKeyClick} />
              <Button  onClick={handleLoginClick} onKeyDownCapture={handleKeyClick} className='loginButton' type="primary" size='large'>Login</Button>
    
          </div>
        </div>
      </div>
    </LoginScreenWrapper>
  )
}
