import styled  from "styled-components";


export const UploadQuestionWrapper = styled.div`
.uploadFormParent{
    padding: 2%;
    margin-top:4%;
    max-width: 30rem;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding-top: 4%;
    min-height: 25rem;
}
.uploadFormElement{
    margin: 4% 0%;
}

.submitButton{
    margin: 0 auto;
    margin-top: 3rem;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: #0074ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.preview{
    // width: 100%;
    background: #ddd8d8;
    margin: 2%;
    // max-height:250px;
    position: relative;
}
.preview-images{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
}
.preview-img{
    width:100%;
    object-fit: contain;
    max-height: 300px;
}
.deleteIcon{
    position: absolute;
    top: -10px;
    right: -5px;
    cursor: pointer;
}
.editIcon{
    display: none;
}
.editIcon:hover{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width:100%;
    height: 100%;
    font-size: 40px;
    background: gray;
    opacity: 0.3;
}

.buttonsSections{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
    justify-content: flex-start;
}
.cameraButton{
    border: none;
    background: #009bff;
    color: white;
    padding: 2%;
    border-radius: 5px;
    width: 25%;
}
.gallaryButton{
    border: none;
    background: #54bc54;
    color: white;
    padding: 2%;
    width: 25%;
    border-radius: 5px;
    cursor: pointer;
}
`;