import React from 'react';
import NoDataF from "assets/images/noData.png";
import { NoDataFoundWrapper } from './NoDataFound.style';

export default function NoDataFound() {
  return (
    <NoDataFoundWrapper>
        <img className='noDataFoundGif' src={NoDataF} alt="" />
    </NoDataFoundWrapper>
  )
}
