import React, { useEffect, useState } from 'react'
import { SubmitVerificationWrapper } from './SubmitVerification.style';
import { Breadcrumb, Input,Modal, message,Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import removeIcon from "assets/images/removeIcon.svg";
import SecuredServices from 'api/securedServices';
import { useNavigate } from 'react-router-dom';
import { RouteDefinitions } from 'api/routes/routeDefinitions';
import { IqLink } from 'helper/constants';

export default function SubmitVerification() {

    const { confirm } = Modal;
    const Navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const qId = params.get('q_id');
    const qName = params.get('q_name');
    const topic = params.get('topic_name');
    const type = params.get('type');
    const concept = params.get('concept_name');
    const concept_id = params.get('concept_id');
    const topicId = params.get('topic_id');
    const [concepts,setConcepts] = useState([]);
    const token = params.get('token');
    const [missionVerificationDetails,setMissionVerificationDetails] = useState({});
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [selectedConcept,setSelectedConcept] = useState({});
    const [inputsArr,setInputsArr] = useState([
        {
            id:1,
            title:"Question",
            extraText:"",
            value:"",
        },
        {
            id:2,
            title:"Final Answer",
            extraText:"",
            value:"",
        },
        {
            id:3,
            title:"Steps Comment ",
            extraText:"(add step no. and detailed explaination if any issues found in steps)",
            value:"",
        }
    ]);
    const [missionInputsArr,setMissionInputsArr] = useState([
        {
            id:1,
            title:"Question 1",
            value :"",
        },
        {
            id:2,
            title:"Question 2",
            value :"",
        },
        {
            id:3,
            title:"Question 3",
            value :"",
        },
        {
            id:4,
            title:"Rubrics",
            value :"",
        }


    ])
    useEffect(()=>{
        setSelectedConcept({
            concept_id: parseInt(concept_id),
            name:concept
        })
    },[concept_id])

    useEffect(()=>{
        if(type=="Mission"){
            SecuredServices.getVerificationDetails(true,qName).then((res)=>{
                console.log(res.data);
                if(res.data.status==1){
                    setMissionVerificationDetails(res.data.data);
                    setSelectedConcept({
                        concept_id: res.data.data[0].concept_id,
                        name:res.data.data[0].concept_name
                    })
                }
                if(res.data.data.length>0){
                    window.open(res.data.data[0].url,'_blank','noopener,noreferrer');
                }
            })
        }
        else
        window.open(`${IqLink}ip-verify/${token}/${topicId}/${qId}`,'_blank','noopener,noreferrer');


    },[qId,topicId,type])


    const handleSubmit = (isAccept)=>{
        setDisableSubmit(true);
        let submitData = {};
        let submitFlag= 1;
        if(type=="Mission"){
            missionInputsArr.forEach((input)=>{
                if(input.value.length==0){
                    message.error("Please fill all the fields");
                    message.warning("fill 'ok' if there is not error in question/step");
                    setDisableSubmit(false);
                    submitFlag = 0;
                    return;
                }
                submitData[input.title] = input.value
            })
        }
        else{
            inputsArr.forEach((input)=>{
                if(input.value.length==0){
                    message.error("Please fill all the fields");
                    message.warning("fill 'ok' if there is not error in question/step");
                    setDisableSubmit(false);
                    submitFlag = 0;
                    return;
                }
                submitData[input.title] = input.value
            })
        }  
        if(selectedConcept.concept_id==-1 || selectedConcept.concept_id==undefined){
            message.error("Please select a concept");
            setDisableSubmit(false);
            submitFlag = 0;
            return;
        }
        console.log(selectedConcept);
        console.log(submitData);
        if(submitFlag==1){
            let data = {
                isAccept: isAccept,
                comment: submitData,
                concept: selectedConcept,
                q_id : qId,
                isMission: type=="Mission"?true:false
            }
            console.log(data);
            SecuredServices.submitVerification(data).then((res)=>{
                console.log(res.data);
                if(res.data.status==1){
                    message.success("Verification Submitted Successfully");
                    Navigate(RouteDefinitions.ROUTE_VERIFY,{replace:true});
                    setDisableSubmit(false);
                }
                else{
                    message.error(res.data.message);
                    setDisableSubmit(false);
                }
            })
            .catch((err)=>{
                console.log(err);
                message.error("Something went wrong,Please try again");
                setDisableSubmit(false);
            })
        }
    }

    useEffect(()=>{
        SecuredServices.getConcepts(topicId).then((res)=>{
            if(res.data.status==1){
                setConcepts(res.data.data.data);
                console.log(res.data.data.data);
            }
        })

    },[topicId])

    const handleClickOnRemove = ()=>{
        if(inputsArr[inputsArr.length-1].value.length!=0){
            confirm({
                title: 'Are you sure you want to remove?',
                content:"Unsaved text in this input box will be lost.",
                okText: 'Yes',
                okType: 'danger',
                cancelText: 'No',
                onOk() {
                    setInputsArr([...inputsArr.slice(0,inputsArr.length-1)])
                },
            })
        }
        else{
            setInputsArr([...inputsArr.slice(0,inputsArr.length-1)])
        }
        }

        const addMoreQuestions = ()=>{
            setMissionInputsArr([...missionInputsArr,{id:missionInputsArr.length+1,title:`Question ${missionInputsArr.length+1}`}])
        }


        const handleChangeConcept = (value)=>{
            if(value==-1){
                setSelectedConcept({
                    concept_id: -1,
                    name:""
                });
            }
            concepts.forEach((concept)=>{
                if(concept.concept_id==value){
                    console.log(concept);
                    setSelectedConcept(concept);
                }
            })

            console.log(selectedConcept);
        }
  return (
    <SubmitVerificationWrapper>
         <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item onClick={()=>{window.history.back()}}>Verify Content</Breadcrumb.Item>
          <Breadcrumb.Item>{qName}</Breadcrumb.Item>
         </Breadcrumb>
        <div>
            <h3>Submit Verification</h3>
            <div className='submitVerificationParent'>
                <div>
                    <p>Uploader Comment</p>
                    <div className='uploader-comment'>
                        <p>{missionVerificationDetails[0]?.uploader_comment}</p>
                    </div>
                </div>
            <div className='topicAndConcept'>
                        <p>Topic: {topic}</p>

                        <div>
                <label htmlFor="" className='label'>Concept:</label>
                <Select defaultValue={-1} style={{width:"100%"}} value={selectedConcept.concept_id} onChange={(value)=>handleChangeConcept(value)} >
                <Select.Option value={-1} key={-1}>Select Concept</Select.Option>
                       {
                        concepts?.map((concept)=>{
                               return <Select.Option value={concept.concept_id} key={concept.concept_id}>{concept.name}</Select.Option>
                           })
                       }
                    </Select>
            </div>
                    </div>
                    {
                        type=="Mission"?  <div className='submitVerification'>
                        <p className='detailedReviewP'>Detailed Review</p>
                        <p className='note'>Note : Write ok if there is no error in question/steps else explain the issue properly in relevent text box.</p>
                       {
                           missionInputsArr.map((input)=>{
                               // console.log(input);
                               return <div className='submitVerificationFormElement' key={input.id}>
                                   <label htmlFor="" className='label'>*{input.title}   :</label>
                                   <div className='textAreaAndRemove'>
                                       <TextArea  value={input.value} onChange={(e)=>{setMissionInputsArr(missionInputsArr.map(mInput=>mInput.id==input.id?{...mInput,value:e.target.value}:mInput))}}></TextArea>
   
                                   </div>
                               </div>
                           })
                       }
                       {/* <div className='addMoreQuestionsPt'>
                           <div> </div>
                           <div className='addMoreQuestions' onClick={addMoreQuestions}><p>Add More Questions</p></div>
                       </div> */}
                       <div className='buttonsSections'>
   
                       <div className='rejectButton' onClick={()=>handleSubmit(0)} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                           <p>Reject</p>
                       </div>
                       <div className='acceptButton' onClick={()=>handleSubmit(1)} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                           <p>Accept</p>
                       </div>
                       </div>
                      
   
                   </div>:
                        <div className='submitVerification'>
                        <p className='detailedReviewP'>Detailed Review</p>
                        <p className='note'>Note : Write ok if there is no error in question/steps else explain the issue properly in relevent text box.</p>
                       {
                           inputsArr.map((input)=>{
                               // console.log(input);
                               return <div className='submitVerificationFormElement' key={input.id}>
                                   <label htmlFor="" className='label'>*{input.title}{input.extraText}   :</label>
                                   <div className='textAreaAndRemove'>
                                       <TextArea  value={input.value} onChange={(e)=>{setInputsArr([...inputsArr.map(item=>item.id===input.id?{...item,value:e.target.value}:item)])}}></TextArea>
   
                                   </div>
                               </div>
                           })
                       }
                       {/* <div className='addMoreStepsPt'>
                           <div> </div>
                           <div className='addMoreSteps' onClick={addMoreSteps}><p>Add More Steps</p></div>
                       </div> */}



                     




                       <div className='buttonsSections'>
   
                       <div className='rejectButton' onClick={()=>handleSubmit(0)} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                           <p>Reject</p>
                       </div>
                       <div className='acceptButton' onClick={()=>handleSubmit(1)} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                           <p>Accept</p>
                       </div>
                       </div>
                      
   
                   </div>
                    }
              
            </div>
        </div>
    </SubmitVerificationWrapper>
  )
}
