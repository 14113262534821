import styled from 'styled-components';
import { device } from 'helper/constants';

const LoginScreenWrapper = styled.div`
.main{
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: #f0f2f5;
    justify-content: center;
    align-items: center;
}
.left{
    display: flex;
    height: 100vh;
    width: 50vw;
    background-color: #1877f2;
    justify-content: center;
    align-items: center;
    img{
        width: 90%;
        height: 90%;
    }
}
.right{
    display: flex;
    height: 100vh;
    width: 50vw;
    background-color: white;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.loginDiv{
    width: 80%;
    height: 30rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    background: #cfdbef;
    padding:2%;
}
.usernameinput,.passwordinput{
    width:90%;
    margin: 2% auto;
    
}
.loginButton{
    width:50%;
    margin-top: 8%;
    text-align: center;
    margin: 8% auto 0%;
}
.label{
    text-align: left;
    margin-left: 5%;
    font-size:12px;
    color: gray;
}
.loginHeader{
    margin-bottom: 8%;
    color: #213861;
}

@media ${device.mobileL}{
    .left{
        display: none;
    }
    .right{
        width: 100vw;
    }
}
`;

export default LoginScreenWrapper;