import styled from "styled-components";


export const SubmitExactWrapper = styled.div`

.submitExactformParent{
    padding: 2%;
    margin-top:4%;
    max-width: 80%;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding-top: 4%;
    min-height: 17rem;
}
.submitExactform{
    margin: 4% 0%;
}
.uploadFormElement{
    margin: 4% 0%;
}

.preview{
    width: 150px;
    background: #ddd8d8;
    margin: 2%;
    max-height:250px;
    position: relative;
}

.preview-images{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.imageAndId{
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 2% 2% 0;
}
.submittedImages{
    max-width:95%;
    height: 200px;
    margin-left:2%;
}
.deleteIcon{
    position: absolute;
    top: -10px;
    right: -5px;
    cursor: pointer;
}
.submitImagesText{
    font-weight: 500;
    font-size: 18px;
    margin-top: 2% !important;
}


.referenceIq{
    display: flex;
    justify-content: end;
    font-size: 11px;
    text-decoration: underline;
    color: #0089ff;
    margin-bottom: 5px;
    cursor: pointer;
}
.submitButton{
    width: 150px;
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: #0074ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.submitImagesTextParent{
    margin-bottom:3%;
}

.buttonsSections{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding:0 5%;
    margin-top:10%;
}
.saveAndTestButton{
    text-align: center;
}
.saveAndTestButton button{
    border: none;
    background: #213861;
    width: 55%;
    padding: 2%;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

.block{
    cursor: not-allowed;
    pointer-events: none;
    
}
.block button{
    opacity: 0.8;
    cursor: not-allowed;
    pointer-events: none;
}
.block button div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5px;
}
.addMoreImagesButton,.prevButton,.skipButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width:130px;
    font-size: 11px;
    height: 30px;
    background: #7e7e7e;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.nextButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 30px;
    background: #0074ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.removeButton{
    border: none;
    background: red;
    color: white;
    border-radius: 5px;
    padding: 3% 10%;
}

.changeTopicButton{
    color: #0089ff;
    text-decoration: underline;
}
.topicName{
    display: flex;
    justify-content: space-between;
}


`;

