// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userid: null,
  first_name: '',
  last_name: '',
  username: '',
  token: '',
  roles:[],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
        console.log(action);
    //   const { userid, first_name, last_name, username, token } = action.payload;
      state.userid = action.payload.userid;
      state.first_name = action.payload.first_name;
      state.last_name = action.payload.last_name;
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.roles = action.payload.roles;
      return state;
    },
    logout: (state) => {
      state.userid = null;
      state.first_name = '';
      state.last_name = '';
      state.username = '';
      state.token = '';
      state.roles=[];
      return state;
    },
    userroles: (state, action) => {
      state.roles = action.payload;
      return state;
    },
  },
});

export const { login, logout, userroles } = authSlice.actions;
export default authSlice.reducer;
