import React,{useEffect, useState} from 'react'
import { AddNewConceptWrapper } from './AddNewConcept.style'
import { Button, Input, Select } from 'antd'
import SecuredServices from 'api/securedServices';
import { FilterData } from 'helper/utils';

export default function AddNewConcept({data,onOkClick}) {

    const [conceptInp,setConceptInp] = useState("");
    const [selectedConcept,setSelectedConcept] = useState();
    const [allConcepts,setAllConcepts] = useState([]);
    const [filteredConcepts,setFileteredConcepts] = useState([]);
    const [existingConceptFlag,setExistingConceptFlag] = useState(true);



    const handleSelectChange = (value)=>{
      setExistingConceptFlag(true);
      setSelectedConcept(value);
    }

    useEffect(()=>{
      SecuredServices.getAllConcepts().then((res)=>{
        if(res.data.status==1){
          setAllConcepts(res.data.data.data);
          setFileteredConcepts(res.data.data.data);
        }
      })
    },[])

    const handleFilter=(input)=>{
      console.log(input);
        let res = FilterData({search:input,searchList:allConcepts});
        console.log(res);
        setFileteredConcepts(res);
    }

    const handleAddClick=()=>{

        let data ={
          concept_id:selectedConcept,
          concept_name:!existingConceptFlag?conceptInp:allConcepts.find((value)=>value.concept_id===selectedConcept)?.name,
          isExiting:existingConceptFlag
        }
        console.log(data);
        return onOkClick(data);
    }
  return (
    <AddNewConceptWrapper>
        <div>
            <p>Topic : {data.topicName}</p>
            <p>Grade : {data.grade}</p>
            <p>Stream : {data.stream}</p>
        </div>
        {
          existingConceptFlag?    <div>
          <Select
          showSearch
          placeholder="Select an existing Concept"
          onSearch={handleFilter}
          value={selectedConcept}
          style={{width:"100%"}}
          onChange={handleSelectChange}
          filterOption={false}
          // filterOption={(input, option) => {
          //   console.log(option);
          //   (option?.name ?? '').toLowerCase().includes(input.toLowerCase());
          //      }
          //     }
          >
            {
              filteredConcepts?.map((value)=>{
                return <Select.Option key={value.concept_id} value={value.concept_id}>{value.name}</Select.Option>
              })
            }
          </Select>
          <div className='addNewConceptParent'><p>didn't found in existing one?</p> <p className='addNewButton' onClick={()=>setExistingConceptFlag(false)}>add new</p></div>
          </div> :
          <div>
            <Input className='addConceptInp' placeholder="Enter New Concept Name" value={conceptInp} onChange={(e)=>setConceptInp(e.target.value)}/>
          </div>
        }
    
     
        <div className="btnParent">
        <Button type="primary" className='addConceptBtn' onClick={handleAddClick} >{existingConceptFlag?"Use Concept ": "Create New And Use"}</Button>
        </div>
    </AddNewConceptWrapper>
  )
}
