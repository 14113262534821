import Styled from "styled-components";


export const ShowImagesWrapper = Styled.div`
.imageSection{
    margin-top: 3rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
`;