import styled from "styled-components";
import { device } from "helper/constants";

export const TestMcqInstanceWrapper = styled.div`
margin:2%;
.h1,h2,h3,h4,h5,h6,p{
    margin: 2px 0;
}

.outerCard{
   
    background: #f4fcff;
    border: 1px solid #c8c2c2;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 5px;
    padding: 2%;
}
.listParent{
    padding :1%;
    margin-top: 1%;
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: center;
    align-items: stretch
}

.card{
    padding: 1% 2%;
    background: radial-gradient(circle, rgba(241,244,239,1) 0%, rgba(228,240,221,1) 35%, rgba(232,245,220,1) 100%);
    border: 1px solid #b3adad;
    border-radius: 7px;
    /* color: white; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin: 1% 0% 0%;
}
.heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
}
.question{
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}
.question p{
    font-size: 14px;
}
.options{
    margin-top: 1%;
}
.optionsDiv{

   
}
.options .option{
    margin-top: 1% !important;
    font-size: 12px;
}
.correctAns{
    margin-top: 1%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.explaination{
    margin-top: 2%;
    font-size: 14px;
    color: #074c24;
}

@media ${device.tabletL}{
    .listParent{
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

`;