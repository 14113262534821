import styled from "styled-components";

export const SubmitVerificationWrapper = styled.div`


.submitVerificationParent{
    padding: 2%;
    margin-top:4%;
    max-width: 80%;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding-top: 4%;
    min-height: 17rem;
}
.submitVerification{
    margin: 4% 0%;
}
.topicAndConcept{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    font-weight:500;
}
.detailedReviewP{
    margin-bottom:2px !important;
    font-size:17px !important;
    font-weight:600;
}
.submitVerificationFormElement{
    margin: 4% 0%;
}
.textAreaAndRemove{
    display: flex;
    gap: 5px;
}
.note{
    color: red;
    margin-top:2% !important;
}
.addMoreQuestionsPt{
    display: flex;
    justify-content: space-between;
    margin-top: 3% !important;
}
.addMoreQuestions{
    width: 130px;
    text-align: center;
    color: #54b454;
    font-weight: bold;
    text-decoration: underline;
    border-radius: 5px;
    padding: 2%;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.uploader-comment{
    width: 100%;
    min-height: 61px;
    border: 1px solid #e2a6a6;
    border-radius: 5px;
    margin: 2% 0%;
    padding: 2%;
}

.buttonsSections{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding:0 5%;
    margin-top:10%;
}

.acceptButton{
    width: 150px;
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: #0074ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.rejectButton{
    width: 150px;
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: red;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

`;