import React from 'react'
import { SearchBarContainer } from './SearchBar.style'
import {
    SearchOutlined
  } from '@ant-design/icons'
import { FilterData } from 'helper/utils';

export default function SearchBar({ data, setFilteredData }) {
    const [searchQuery, setSearchQuery] = React.useState("");

    const handleSearch = (e)=>{
        console.log(e.target.value);
        setSearchQuery(e.target.value);
        let data1 =FilterData({ search: e.target.value, searchList: data })
        setFilteredData(data1);
    }
    return (
        <SearchBarContainer>
            <div className='searchBarContainer'>
                {/* search bar feature */}
                <div className='searchBar' >
                    <SearchOutlined style={{ color: "#213861", fontWeight: "bold", fontSize: "15px" }} />
                    <input type="text" className='searchInput' placeholder='Type to search...' value={searchQuery} onChange={handleSearch} />
                </div>
            </div>
        </SearchBarContainer>

    )
}
