import React,{useEffect, useState,useRef} from 'react'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { ReactCropImageWrapper } from './ReactCropImage.style';
import { useLocation } from 'react-router-dom';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export default function ReactCropImage({src,onOkClick}) {
    const [crop, setCrop] = useState();
    const location = useLocation();
    const canvasRef = useRef(null);
    const cropperRef = useRef(null);
    const [image,setImage] = useState(null);
    useEffect(()=>{
        console.log(src);
    },[])


    const handleOkClick = () => {
        const cropper = cropperRef.current?.cropper;
        console.log(cropper);
        const canvas = cropper.getCroppedCanvas();
        console.log(canvas);
        canvas.toBlob((blob) => {
            const randomTwoDigits = Math.floor(Math.random() * 90) + 10;
            const fileName = `cropped-image-${randomTwoDigits}.png`;
            const file = new File([blob], fileName, { type: 'image/png' });
            const fileData = {
              file: file,
              preview: URL.createObjectURL(blob),
            };
            onOkClick(fileData);
          }, 'image/png');

    }
      
  return (
    <ReactCropImageWrapper>
         <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
        <div className='mainCropImageDiv'>



<Cropper
          src={src}
          style={{ height: 400, width: '100%' }}
          guides={false}
          ref={cropperRef}
        />
        </div>
            
        <div className='buttonsSections'>
        <button className='cameraButton'  onClick={()=>{
            
        }} >Cancel</button>
        <button className='gallaryButton'  onClick={handleOkClick}>Ok</button>
        </div>
       
    </ReactCropImageWrapper>
  )
}
