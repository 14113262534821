
import './App.css';
import { Route, Routes } from 'react-router-dom';
import LoginScreen from './screens/login/loginScreen';
import DashboardScreen from './screens/dashboard/dashboardScreen';
import "./assets/scss/helper.scss";
import CustomRoutes from 'api/routes/routes';
import "bootstrap-icons/font/bootstrap-icons.css";
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;



function App() {
  return (

    <div className="App">
      <CustomRoutes/>
    </div>
  );
}

export default App;
