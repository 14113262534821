import styled from "styled-components";

export const EditUserWrapper = styled.div`
.form{
    margin-top:4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background: #f0f0f0;
    border-radius: 10px;
    border: 1px solid #a8a8a8;
    padding: 5% 0%;
}
.formElement{
    padding: 1%;
    width: 90%;
}
.button{
    margin-top: 6%;
}
.submitButton{
    border: none;
    padding: 4px 45px;
    height: 30px;
    background: #007aff;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}

`;