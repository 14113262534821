import React, { useEffect } from 'react'
import { AdminActionsWrapper } from './AdminActions.style'
import { useLocation } from 'react-router-dom'
import { RouteDefinitions } from 'api/routes/routeDefinitions';
import ManageUser from './ManageUsers/ManageUser';
import ManageContent from './ManageContent/ManageContent';
import ManageConcepts from './ManageConcepts/ManageConcepts';

export default function AdminActions() {
    const location = useLocation();

    useEffect(()=>{
        console.log(location.pathname);
        // if(){}
    },[location.pathname])
  return (
    <AdminActionsWrapper>
        <h3>Admin Actions</h3>
        {
            location.pathname==RouteDefinitions.ROUTE_ADMIN_MANAGE_USERS?<ManageUser/>:
            location.pathname==RouteDefinitions.ROUTE_ADMIN_MANAGE_CONTENT?<ManageContent/>:
            location.pathname ===RouteDefinitions.ROUTE_ADMIN_MANAGE_CONCEPTS?<ManageConcepts/>:<></>
        }
    </AdminActionsWrapper>
  )
}
